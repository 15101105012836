import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import * as S from "./styles";
var licenseTypeMap = {
  'ONPREMISE': 'On Premise',
  'SAAS': 'SaaS',
  'MANAGED': 'Managed services'
};
var licensePeriodMap = {
  'MONTH': 'monthly',
  'QUARTER': 'quarterly',
  'YEAR': 'yearly'
};
export var LicenseTabel = function LicenseTabel(_ref) {
  var licenses = _ref.licenses,
      history = _ref.history,
      onProlong = _ref.onProlong,
      onLicenseChange = _ref.onLicenseChange;
  return React.createElement(S.Wrapper, null, React.createElement(S.Row, null, React.createElement(S.HeaderRow, null, React.createElement(S.LicenseUuid, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.LicenseTabel.LicenseTabel.170969919",
    defaultMessage: "License UUID"
  })), React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.LicenseTabel.LicenseTabel.3508413817",
    defaultMessage: "Created"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.LicenseTabel.LicenseTabel.1952810469",
    defaultMessage: "Type"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.LicenseTabel.LicenseTabel.3991044751",
    defaultMessage: "Billing"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.LicenseTabel.LicenseTabel.200901494",
    defaultMessage: "Number of devices"
  })), React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.LicenseTabel.LicenseTabel.1097251999",
    defaultMessage: "Expires"
  })))), licenses && licenses.map(function (license) {
    var created = new Date(license.node.created);
    var expires = new Date(license.node.expires);
    return React.createElement(S.Row, {
      "data-test": "licenseEntry",
      "data-test-id": license.node.id,
      key: license.node.id,
      onClick: function onClick(evt) {
        evt.stopPropagation();
        history.push("/license-details/".concat(license.node.id, "/"));
      }
    }, React.createElement(S.LicenseUuid, null, license.node.uuid), React.createElement(S.DateField, null, React.createElement(FormattedDate, {
      value: created
    })), React.createElement(S.Value, null, licenseTypeMap[license.node.licenseType]), React.createElement(S.Value, null, licensePeriodMap[license.node.licensePeriod]), React.createElement(S.Value, null, license.node.numberOfDevices, React.createElement("br", null), !license.node.hasExpired ? React.createElement("button", {
      style: {
        'color': '#2f56a3'
      },
      onClick: function onClick(evt) {
        evt.stopPropagation();
        onLicenseChange(license.node);
      }
    }, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.LicenseTabel.LicenseTabel.3973111846",
      defaultMessage: "Modify the number of devices"
    })) : null), React.createElement(S.DateField, null, React.createElement(FormattedDate, {
      value: expires
    }), React.createElement("br", null), license.node.hasExpired ? React.createElement("button", {
      style: {
        'color': '#d22'
      }
    }, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.LicenseTabel.LicenseTabel.2717763435",
      defaultMessage: "Expired"
    })) : null, license.node.canBeProlonged ? React.createElement("button", {
      style: {
        'color': '#2f56a3'
      },
      onClick: function onClick(evt) {
        evt.stopPropagation();
        onProlong(license.node.id);
      }
    }, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.LicenseTabel.LicenseTabel.3738098677",
      defaultMessage: "Click to extend the license"
    })) : null));
  }));
};