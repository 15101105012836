import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  min-width: 120px;\n  margin: 0 15px;\n  \n  label {\n    white-space: nowrap;\n  }\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  grid-area: unitPrice;\n  color: ", ";\n\n  .price_gross {\n    color: ", ";\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-area: trash;\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n    p {\n      text-align: right;\n    }\n  "]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  color: ", ";\n\n  .price_gross {\n    color: ", ";\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n  ", "\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n    display: block;\n  "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n    font-weight: normal;\n    flex-direction: column;\n  "]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  justify-content: center;\n  font-weight: bold;\n  ", "\n\n  p {\n    margin: 0;\n    \n    .price_gross {\n      display: block;\n      white-space: nowrap;\n      color: $base-font-color-semitransparent;\n      font-weight: normal;\n      font-size: 75%;\n    }\n    \n    .price_net {\n      display: block;\n    }\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: rgba(125, 125, 125, 0.6);\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: ", ";\n  text-align: left;\n  text-transform: uppercase;\n  margin-bottom: 6px;\n  color: ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  white-space: nowrap;\n  padding: 0px 15px;\n  font-weight: 600;\n  > span > span {\n    font-weight: 500;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    flex-flow: column;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-columns: max-content;\n  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));\n  margin-left: -15px;\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  margin: 6px 0;\n  text-align: left;\n  margin-bottom: 10px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    margin-left: 0px;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: description;\n  height: 100%;\n  margin-top: 20px;\n  margin-left: 20px;\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: photo;\n  display: flex;\n  align-items: flex-start;\n  align-self: top;\n  width: 70px;\n  height: 90px;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 2fr 2fr;\n    grid-row-gap: 15px;\n    grid-column-gap: 20px;\n    grid-template-areas: \"photo description description\"\n    \"trash description description\"\n    \"trash unitPrice quantity\"\n    \". . totalPrice\";\n    padding: 1rem 0rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  min-height: 140px;\n  max-height: min-content;\n  width: 100%;\n  grid-template-areas: \"photo description unitPrice quantity totalPrice trash\";\n  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 0.5fr;\n  align-items: center;\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n  padding: 0.8rem 0.5rem;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.mediumScreen(_templateObject2()));
export var QuantityButtons = styled.div(_templateObject3());
export var Photo = styled.div(_templateObject4());
export var Description = styled.div(_templateObject5(), media.mediumScreen(_templateObject6()));
export var Sku = styled.p(_templateObject7());
export var Attributes = styled.div(_templateObject8(), media.mediumScreen(_templateObject9()));
export var SingleAttribute = styled.p(_templateObject10());
export var Name = styled.p(_templateObject11(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.secondaryDark;
});
export var LightFont = styled.span(_templateObject12(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Price = styled.div(_templateObject13(), function (props) {
  return props.theme.typography.h4FontSize;
}, media.mediumScreen(_templateObject14()));
export var PriceLabel = styled.p(_templateObject15(), media.mediumScreen(_templateObject16()));
export var TotalPrice = styled(Price)(_templateObject17(), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, media.mediumScreen(_templateObject18()));
export var Trash = styled.div(_templateObject19());
export var UnitPrice = styled(Price)(_templateObject20(), function (props) {
  return props.theme.colors.secondaryDark;
}, function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var Quantity = styled.div(_templateObject21());