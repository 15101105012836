import * as React from "react";
import { Mutation } from "react-apollo";
export function TypedMutation(mutation, update) {
  return function (props) {
    var _ref = props,
        children = _ref.children,
        onCompleted = _ref.onCompleted,
        onError = _ref.onError,
        variables = _ref.variables;
    var currency = window.localStorage.getItem('currency');
    return React.createElement(Mutation, {
      mutation: mutation,
      onCompleted: onCompleted,
      onError: onError,
      variables: variables,
      update: update,
      context: {
        headers: {
          Currency: currency ? JSON.parse(currency) : 'EUR'
        }
      }
    }, children);
  };
}