import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import * as S from "./styles";
var licenseTypeMap = {
  'onpremise': 'On Premise',
  'saas': 'SaaS',
  'managed': 'Managed services'
};
export var AcceptedContracts = function AcceptedContracts(_ref) {
  var user = _ref.user;
  var i = 0;
  return React.createElement("div", {
    className: "accepted-contracts-container"
  }, React.createElement(S.Wrapper, null, React.createElement(S.Row, null, React.createElement(S.HeaderRow, null, React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "@next.pages.AcceptedContracts.AcceptedContracts.1762361636",
    defaultMessage: "Date of acceptance"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.pages.AcceptedContracts.AcceptedContracts.2615420898",
    defaultMessage: "Contract"
  })))), user.contracts && user.contracts.map(function (contract) {
    var date = new Date(contract.date);
    var title = contract.title in licenseTypeMap ? licenseTypeMap[contract.title] : contract.title;
    return React.createElement(S.Row, {
      key: i++
    }, React.createElement(S.DateField, null, React.createElement(FormattedDate, {
      value: date
    })), React.createElement(S.Value, null, React.createElement("a", {
      href: contract.url,
      target: "_blank"
    }, title)));
  })));
};