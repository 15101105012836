import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", ";\n  border-bottom: 1px solid ", ";\n  ", "\n  color: ", ";\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return "1.1rem ".concat(props.theme.spacing.gutter);
}, function (props) {
  return props.theme.colors.light;
}, function (_ref) {
  var selected = _ref.selected,
      theme = _ref.theme;
  return selected && "font-weight: ".concat(theme.typography.boldFontWeight, ";");
}, function (props) {
  return props.disabled ? props.theme.colors.disabled : "unset";
}, function (props) {
  return !props.disabled && "&:hover {\n    background-color: ".concat(props.theme.colors.secondary, ";\n    color: ").concat(props.theme.colors.white, ";\n    font-weight: ").concat(props.theme.typography.boldFontWeight, ";\n  }");
});