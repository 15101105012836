import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      mutation LicenseRegister($id: ID!, $instance: String!) {\n        licenseRegister(id: $id, instance: $instance) {\n          licenseErrors {\n            field\n            message\n            code\n          }\n        }\n      }\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    query License($id: ID!) { \n      license(id: $id) {\n        id\n        uuid\n        created\n        licensePeriod\n        licenseType\n        numberOfDevices\n        expires\n        instance\n        code\n        hasExpired\n      }\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React, { useState } from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";
import { getAuthToken } from "@saleor/sdk";
import { Loader } from "@components/atoms";
import { licensesUrl } from "../../../app/routes";
import { Link } from "react-router-dom";
import { TextField } from "@components/molecules";
import { Button } from "@components/atoms";
import { Formik } from "formik";
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost';
import * as S from "./styles";
var licenseTypeMap = {
  'ONPREMISE': 'On Premise',
  'SAAS': 'SaaS',
  'MANAGED': 'Managed services'
};
var licensePeriodMap = {
  'MONTH': 'monthly',
  'QUARTER': 'quarterly',
  'YEAR': 'yearly'
};
export var View = function View(_ref) {
  var _license$instance, _license$code;

  var id = _ref.match.params.id;
  var intl = useIntl();
  var getLicense = gql(_templateObject());
  var authToken = getAuthToken();
  var client = new ApolloClient({
    uri: process.env.API_URI,
    headers: {
      Authorization: authToken ? "JWT ".concat(authToken) : null
    }
  });

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      license = _useState2[0],
      setLicense = _useState2[1];

  if (!license) {
    client.query({
      query: getLicense,
      variables: {
        id: id
      }
    }).then(function (resp) {
      setLicense(resp.data.license);
    });
  }

  var created = new Date(license === null || license === void 0 ? void 0 : license.created);
  var expires = new Date(license === null || license === void 0 ? void 0 : license.expires);

  var handleSubmit = function handleSubmit(values) {
    var REGISTER = gql(_templateObject2());
    client.mutate({
      mutation: REGISTER,
      variables: {
        id: id,
        instance: values.instance
      }
    }).then(function (resp) {
      client.query({
        query: getLicense,
        variables: {
          id: id
        }
      }).then(function (resp) {
        setLicense(resp.data.license);
      });
    });
  };

  return !license ? React.createElement(Loader, null) : React.createElement("div", {
    className: "order-details container"
  }, React.createElement(Link, {
    className: "order-details__link",
    to: licensesUrl
  }, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.3160754622",
    defaultMessage: "Go back to Licenses List"
  })), React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.4206032895",
    defaultMessage: "License"
  }), " ", license === null || license === void 0 ? void 0 : license.uuid), (license === null || license === void 0 ? void 0 : (_license$instance = license.instance) === null || _license$instance === void 0 ? void 0 : _license$instance.length) ? React.createElement("div", null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.511101383",
    defaultMessage: "Instance"
  }), " ", license.instance) : React.createElement(S.ContentEdit, null, React.createElement(Formik, {
    initialValues: {
      instance: ''
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      handleSubmit({
        instance: values.instance
      });
      setSubmitting(false);
    },
    validate: function validate(values) {
      var errors = {};

      if (!values.instance) {
        errors.instance = 'Required';
      } else if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(values.instance)) {
        errors.instance = 'Invalid instance UUID';
      }

      return errors;
    }
  }, function (_ref3) {
    var values = _ref3.values,
        errors = _ref3.errors,
        touched = _ref3.touched,
        handleChange = _ref3.handleChange,
        handleBlur = _ref3.handleBlur,
        handleSubmit = _ref3.handleSubmit,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      onSubmit: handleSubmit,
      "data-test": "licenseRegisterForm"
    }, React.createElement(S.ContentEditOneLine, null, React.createElement(TextField, {
      name: "instance",
      label: React.createElement(FormattedMessage, {
        id: "licenseDetails.views.LicenseDetails.View.679614940",
        defaultMessage: "Instance UUID"
      }),
      type: "text",
      value: values.instance,
      onBlur: handleBlur,
      onChange: handleChange,
      errors: errors.instance && touched.instance ? [{
        message: errors.instance
      }] : null
    })), React.createElement(S.FormButtons, null, React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting || !isValid,
      size: "sm"
    }, React.createElement(FormattedMessage, {
      id: "licenseDetails.views.LicenseDetails.View.3924138217",
      defaultMessage: "Register instance"
    }))));
  })), React.createElement(S.Wrapper, null, React.createElement(S.Row, null, React.createElement(S.HeaderRow, null, React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.3508413817",
    defaultMessage: "Created"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.1952810469",
    defaultMessage: "Type"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.3991044751",
    defaultMessage: "Billing"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.200901494",
    defaultMessage: "Number of devices"
  })), React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.1097251999",
    defaultMessage: "Expires"
  })))), React.createElement(S.Row, null, React.createElement(S.DateField, null, React.createElement(FormattedDate, {
    value: created
  })), React.createElement(S.Value, null, licenseTypeMap[license.licenseType]), React.createElement(S.Value, null, licensePeriodMap[license.licensePeriod]), React.createElement(S.Value, null, license.numberOfDevices), React.createElement(S.DateField, null, React.createElement(FormattedDate, {
    value: expires
  }), React.createElement("br", null), license.hasExpired ? React.createElement("button", null, React.createElement(FormattedMessage, {
    id: "licenseDetails.views.LicenseDetails.View.2717763435",
    defaultMessage: "Expired"
  })) : null))), (license === null || license === void 0 ? void 0 : (_license$code = license.code) === null || _license$code === void 0 ? void 0 : _license$code.length) ? React.createElement(S.Code, null, license === null || license === void 0 ? void 0 : license.code) : null);
};
export default View;