import React from "react";
import { CreditCardIcon } from "@components/atoms";
import * as S from "./styles";
export var CreditCardNumberWithIcon = function CreditCardNumberWithIcon(_ref) {
  var creditCardProvider = _ref.creditCardProvider,
      last4Digits = _ref.last4Digits;
  if (creditCardProvider && creditCardProvider.length) return React.createElement("div", null, React.createElement(CreditCardIcon, {
    creditCardProvider: creditCardProvider
  }), React.createElement(S.Wrapper, null, "XXXX XXXX XXXX ", last4Digits));
  return React.createElement("div", null, React.createElement(S.Wrapper, null, "XXXX XXXX XXXX ", last4Digits));
};