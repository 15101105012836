import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import { useCart } from "@saleor/sdk";
import { isEmpty } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useLocale from "@hooks/useLocale";
import { Loader } from "@components/atoms";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { getGraphqlIdFromDBId, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedProductDetailsQuery } from "./queries";

var canDisplay = function canDisplay(product) {
  return maybe(function () {
    return !!product.descriptionJson && !!product.name && !!product.pricing && !!product.variants;
  });
};

var jsonToText = function jsonToText(jst) {
  var obj = null;

  try {
    obj = JSON.parse(jst);
  } catch (e) {}

  if (obj && 'blocks' in obj && obj.blocks) {
    var texts = [];

    for (var i in obj.blocks) {
      texts.push(obj.blocks[i].text);
    }

    return texts.join(' ');
  }

  return null;
};

var extractMeta = function extractMeta(product) {
  var _product$pricing, _product$pricing$pric, _product$pricing$pric2, _product$pricing2, _product$pricing2$pri, _product$pricing2$pri2, _product$category, _product$thumbnail;

  return {
    custom: [{
      content: (_product$pricing = product.pricing) === null || _product$pricing === void 0 ? void 0 : (_product$pricing$pric = _product$pricing.priceRange) === null || _product$pricing$pric === void 0 ? void 0 : (_product$pricing$pric2 = _product$pricing$pric.start) === null || _product$pricing$pric2 === void 0 ? void 0 : _product$pricing$pric2.gross.amount.toString(),
      property: "product:price:amount"
    }, {
      content: (_product$pricing2 = product.pricing) === null || _product$pricing2 === void 0 ? void 0 : (_product$pricing2$pri = _product$pricing2.priceRange) === null || _product$pricing2$pri === void 0 ? void 0 : (_product$pricing2$pri2 = _product$pricing2$pri.start) === null || _product$pricing2$pri2 === void 0 ? void 0 : _product$pricing2$pri2.gross.currency,
      property: "product:price:currency"
    }, {
      content: product.isAvailable ? "in stock" : "out off stock",
      property: "product:isAvailable"
    }, {
      content: (_product$category = product.category) === null || _product$category === void 0 ? void 0 : _product$category.name,
      property: "product:category"
    }],
    description: product.seoDescription || jsonToText(product.descriptionJson),
    image: (product === null || product === void 0 ? void 0 : (_product$thumbnail = product.thumbnail) === null || _product$thumbnail === void 0 ? void 0 : _product$thumbnail.url) || null,
    title: product.seoTitle || product.name,
    type: "product.item",
    url: window.location.href
  };
};

var PageWithQueryAttributes = function PageWithQueryAttributes(props) {
  var product = props.product;
  var history = useHistory();
  var search = history.location.search;
  var searchQueryAttributes = queryString.parse(search);

  var onAttributeChangeHandler = function onAttributeChangeHandler(slug, value) {
    history.replace(queryString.stringifyUrl({
      query: _defineProperty({}, slug, value),
      url: "".concat(history.location.pathname).concat(history.location.search)
    }, {
      skipEmptyString: true
    }));
  };

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      queryAttributes = _useState2[0],
      setQueryAttributes = _useState2[1];

  useEffect(function () {
    if (!isEmpty(searchQueryAttributes)) {
      var _queryAttributes = {};
      product.variants.forEach(function (_ref) {
        var attributes = _ref.attributes;
        attributes.forEach(function (_ref2) {
          var attribute = _ref2.attribute,
              values = _ref2.values;
          var selectedAttributeValue = searchQueryAttributes[attribute.slug];

          if (selectedAttributeValue && values[0].value === selectedAttributeValue) {
            if (isEmpty(_queryAttributes) || !attributes.filter(function (_ref3) {
              var id = _ref3.attribute.id,
                  values = _ref3.values;
              return _queryAttributes[id] && _queryAttributes[id] !== values[0].value;
            }).length) {
              _queryAttributes[attribute.id] = selectedAttributeValue;
            }
          }
        });
      });
      setQueryAttributes(_queryAttributes);
    }
  }, [product.variants.length]);
  useEffect(function () {
    history.replace(history.location.pathname);
  }, [queryAttributes]);
  return React.createElement(Page, _extends({}, props, {
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  }));
};

var View = function View(_ref4) {
  var match = _ref4.match;

  var _useCart = useCart(),
      addItem = _useCart.addItem,
      items = _useCart.items;

  var _useLocale = useLocale(),
      locale = _useLocale.locale;

  var applyTranslation = function applyTranslation(languageCode, obj) {
    var trans = obj['trans' + languageCode];

    if (trans) {
      for (var p in trans) {
        if (trans[p] && trans[p].length) obj[p] = trans[p];
      }
    }
  };

  return React.createElement(TypedProductDetailsQuery, {
    loaderFull: true,
    variables: {
      id: getGraphqlIdFromDBId(match.params.id, "Product")
    },
    errorPolicy: "all",
    key: match.params.id
  }, function (_ref5) {
    var data = _ref5.data,
        loading = _ref5.loading;
    return React.createElement(NetworkStatus, null, function (isOnline) {
      var product = data.product;

      if (canDisplay(product)) {
        if (locale != 'en') {
          var languageCode = locale.toUpperCase();
          applyTranslation(languageCode, product);
          applyTranslation(languageCode, product.category);

          for (var i in product.attributes) {
            applyTranslation(languageCode, product.attributes[i].attribute);

            for (var j in product.attributes[i].values) {
              applyTranslation(languageCode, product.attributes[i].values[j]);
            }
          }

          for (var i in product.variants) {
            for (var j in product.variants[i].attributes) {
              applyTranslation(languageCode, product.variants[i].attributes[j].attribute);

              for (var k in product.variants[i].attributes[j].values) {
                applyTranslation(languageCode, product.variants[i].attributes[j].values[k]);
              }
            }
          }
        }

        return React.createElement(MetaWrapper, {
          meta: extractMeta(product)
        }, React.createElement(PageWithQueryAttributes, {
          product: product,
          add: addItem,
          items: items
        }));
      }

      if (loading) {
        return React.createElement(Loader, null);
      }

      if (product === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }
    });
  });
};

export default View;