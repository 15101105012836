import * as React from "react";
import { isEqual } from "lodash";
import { TaxedMoney } from "../../containers";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
/**
 * Renders formatted price for chosen variant or product.
 * Price ranges and discounts are additionally formatted available.
 */

export var getProductPrice = function getProductPrice(productPricingRange, variantPricing, quantity, licensePricing) {
  if (quantity == null) quantity = licensePricing ? 4 : 1;

  if (variantPricing) {
    var _variantPricing = JSON.parse(JSON.stringify(variantPricing));

    if (licensePricing && licensePricing.length) {
      var _price = null;

      for (var i in licensePricing) {
        if (quantity >= licensePricing[i].numberOfDevices) {
          _price = licensePricing[i].price;
        } else {
          break;
        }
      }

      if (_price) {
        _variantPricing.price = JSON.parse(JSON.stringify(_price));
      }
    }

    if ('gross' in _variantPricing.priceUndiscounted) {
      _variantPricing.priceUndiscounted.gross.amount *= quantity;
      _variantPricing.priceUndiscounted.net.amount *= quantity;
    } else {
      _variantPricing.priceUndiscounted.amount *= quantity;
      _variantPricing.priceUndiscounted.amount *= quantity;
    }

    if ('gross' in _variantPricing.price) {
      _variantPricing.price.gross.amount *= quantity;
      _variantPricing.price.net.amount *= quantity;
    } else {
      _variantPricing.price.amount *= quantity;
      _variantPricing.price.amount *= quantity;
    }

    if (isEqual(_variantPricing.priceUndiscounted, _variantPricing.price)) {
      return React.createElement(TaxedMoney, {
        taxedMoney: _variantPricing.price,
        showBothPrices: true
      });
    }

    return React.createElement(React.Fragment, null, React.createElement(S.UndiscountedPrice, null, React.createElement(TaxedMoney, {
      taxedMoney: _variantPricing.priceUndiscounted,
      showBothPrices: true
    })), "\xA0\xA0\xA0\xA0", React.createElement(TaxedMoney, {
      taxedMoney: _variantPricing.price,
      showBothPrices: true
    }));
  }

  if (!productPricingRange.priceRange) {
    return React.createElement(React.Fragment, null);
  }

  var _productPricingRange$ = productPricingRange.priceRange,
      start = _productPricingRange$.start,
      stop = _productPricingRange$.stop;

  var _start = JSON.parse(JSON.stringify(start));

  var _stop = JSON.parse(JSON.stringify(stop));

  _start.gross.amount *= quantity;
  _start.net.amount *= quantity;
  _stop.gross.amount *= quantity;
  _stop.net.amount *= quantity;

  if (isEqual(_start, _stop)) {
    return React.createElement(TaxedMoney, {
      taxedMoney: _start,
      showBothPrices: false
    });
  }

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "startingat"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.stockHelpers.3119879752",
    defaultMessage: "Prices starting at"
  })), React.createElement(TaxedMoney, {
    taxedMoney: _start,
    showBothPrices: true
  }));
};
export var canAddToCart = function canAddToCart(items, isAvailableForPurchase, variantId, variantStock, quantity) {
  var cartItem = items === null || items === void 0 ? void 0 : items.find(function (item) {
    return item.variant.id === variantId;
  });
  var syncedQuantityWithCart = cartItem ? quantity + ((cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) || 0) : quantity;
  return isAvailableForPurchase && quantity > 0 && !!variantId && variantStock >= syncedQuantityWithCart;
};
/**
 * Returns how many items you can add to the cart. Takes in account quantity already in cart.
 */

export var getAvailableQuantity = function getAvailableQuantity(items, variantId, variantStock) {
  var cartItem = items === null || items === void 0 ? void 0 : items.find(function (item) {
    return item.variant.id === variantId;
  });
  var quantityInCart = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) || 0;
  return variantStock - quantityInCart;
};