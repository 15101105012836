import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  padding-bottom: 1.5rem;\n  color: ", ";\n  transition: all 0.3s ease;\n\n  &:hover {\n    color: ", ";\n    text-shadow: 1px 0 ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  text-transform: \"uppercase\";\n  padding-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  height: 100%;\n  /* height: auto; */\n  padding: 2.5rem 3rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.divider;
});
export var MenuHeader = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.secondaryDark;
});
export var MenuItem = styled.div(_templateObject3(), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
});