import React from "react";
import { CreditCardGrid } from "@components/organisms";
export var StoredCards = function StoredCards(_ref) {
  var user = _ref.user;
  var creditCards = [];

  if ('storedPaymentSources' in user && user.storedPaymentSources) {
    for (var i in user.storedPaymentSources) {
      var cc = user.storedPaymentSources[i].creditCardInfo;
      creditCards.push(cc);
    }
  }

  return React.createElement("div", {
    className: "stored-cards-container"
  }, React.createElement(CreditCardGrid, {
    creditCards: creditCards
  }));
};