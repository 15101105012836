import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ThankYou } from "@components/organisms";
import { BASE_URL } from "@temp/core/config";
import { generateGuestOrderDetailsUrl } from "@utils/core";

var ThankYouPage = function ThankYouPage(_ref) {
  _objectDestructuringEmpty(_ref);

  var location = useLocation();
  var history = useHistory();
  var _location$state = location.state,
      token = _location$state.token,
      orderNumber = _location$state.orderNumber,
      gateway = _location$state.gateway;
  return React.createElement(ThankYou, {
    continueShopping: function continueShopping() {
      return history.push(BASE_URL);
    },
    orderNumber: orderNumber,
    orderDetails: function orderDetails() {
      return history.push(generateGuestOrderDetailsUrl(token));
    },
    isBankTransfer: gateway == 'dynfi.payments.bank_transfer'
  });
};

export { ThankYouPage };