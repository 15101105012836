import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2em;\n  color: #1c2543;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2em;\n  font-weight: bold;\n\n  a {\n    font-weight: bold;\n    color: #2f56a3;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr 1.5fr;\n  grid-gap: 3rem;\n\n  button {\n    height: max-content;\n    margin-top: 40px;    \n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  .title {\n    font-size: 1.1rem;\n    color: ", ";\n  }\n  margin-top: 10px;\n  padding-top: 10px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  div > div {\n    border: 1px solid #c4c4c4;\n    border-radius: 10px;\n  }\n\n  div > div:hover, div > div:focus {\n    border: 1px solid ", ";\n  }\n  margin-top: 20px;\n  padding-top: 20px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 20px;\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    white-space: break-spaces;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 180%;\n  font-weight: bold;  \n  margin-bottom: calc(", " * 1.5);\n  line-height: 1.5rem;\n  white-space: nowrap;\n\n  ", "\n    \n  .startingat {\n    font-size: 50%;\n    margin-bottom: 0.5rem;\n  }\n    \n  .price_gross {  \n    color: ", ";\n    font-weight: normal;\n    font-size: 75%;\n    margin-left: 0.5rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  text-transform: capitalize;\n  font-weight: ", ";\n  margin-bottom: calc(", " * 1.5);\n  font-size: 2.5rem;\n  line-height: 2.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var AddToCartSelection = styled.div(_templateObject());
export var ProductNameHeader = styled.h3(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var ProductPricing = styled.h4(_templateObject3(), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject4()), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var UndiscountedPrice = styled.span(_templateObject5(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var VariantPicker = styled.div(_templateObject6());
export var QuantityInput = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.secondary;
});
export var ErrorMessage = styled.p(_templateObject8(), function (props) {
  return props.theme.colors.error;
});
export var ComponentSelector = styled.div(_templateObject9(), function (props) {
  return props.theme.colors.secondaryDark;
});
export var Footer = styled.div(_templateObject10());
export var Contract = styled.div(_templateObject11());
export var Warning = styled.div(_templateObject12());