import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import * as S from "./styles";
var subscriptionTypeMap = {
  'COMMUNITY': 'Community',
  'BASIC': 'Basic',
  'STANDARD': 'Standard',
  'PREMIUM': 'Premium'
};
var cpusMap = {
  'ONE': '1',
  'TWO': '2',
  'FOUR': '4',
  'UNLIMITED': '1-4'
};
export var ProxmoxTabel = function ProxmoxTabel(_ref) {
  var licenses = _ref.licenses,
      history = _ref.history,
      onProlong = _ref.onProlong;
  return React.createElement(S.Wrapper, null, React.createElement(S.Row, null, React.createElement(S.HeaderRow, null, React.createElement(S.LicenseUuid, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.170969919",
    defaultMessage: "License UUID"
  })), React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.3508413817",
    defaultMessage: "Created"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.421686156",
    defaultMessage: "Subscription type"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.3742516100",
    defaultMessage: "Number of CPU sockets"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.766124952",
    defaultMessage: "Subscription number"
  })), React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.1097251999",
    defaultMessage: "Expires"
  })))), licenses && licenses.map(function (license) {
    var created = new Date(license.node.created);
    var expires = new Date(license.node.expires);
    return React.createElement(S.Row, {
      "data-test": "licenseEntry",
      "data-test-id": license.node.id,
      key: license.node.id,
      onClick: function onClick(evt) {
        evt.stopPropagation();
        history.push("/proxmox-details/".concat(license.node.id, "/"));
      }
    }, React.createElement(S.LicenseUuid, null, license.node.uuid), React.createElement(S.DateField, null, React.createElement(FormattedDate, {
      value: created
    })), React.createElement(S.Value, null, subscriptionTypeMap[license.node.subscriptionType]), React.createElement(S.Value, null, cpusMap[license.node.numberOfCpus]), React.createElement(S.Value, null, license.node.subscriptionNumber), React.createElement(S.DateField, null, React.createElement(FormattedDate, {
      value: expires
    }), React.createElement("br", null), license.node.hasExpired ? React.createElement("button", null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.2717763435",
      defaultMessage: "Expired"
    })) : React.createElement("button", {
      onClick: function onClick(evt) {
        evt.stopPropagation();
        onProlong(license.node.id);
      }
    }, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProxmoxTabel.ProxmoxTabel.788901468",
      defaultMessage: "Click to prolong"
    }))));
  }));
};