import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _readOnlyError from "@babel/runtime/helpers/readOnlyError";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      mutation LicenseChange($id: ID!, $numberOfDevices: Int!) {\n        licenseChange(id: $id, numberOfDevices: $numberOfDevices) {\n          licenseErrors {\n            field\n            message\n            code\n          }\n        }\n      }\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      mutation LicenseProlong($id: ID!) {\n        licenseProlong(id: $id) {\n          licenseErrors {\n            field\n            message\n            code\n          }\n        }\n      }\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query LicensesByUser {\n    me {\n      licenses(first: 100) {\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        edges {\n          node {\n            id\n            uuid\n            created\n            licenseType\n            licensePeriod\n            numberOfDevices\n            expires\n            hasExpired\n            canBeProlonged\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React, { useState } from "react";
import { getAuthToken } from "@saleor/sdk";
import { FormattedMessage } from "react-intl";
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost';
import { Loader } from "@components/atoms";
import { LicenseTabel } from "@components/molecules";
import { Modal } from "@components/organisms";
import * as S from "./styles";
var licensesByUser = gql(_templateObject());
export var Licenses = function Licenses(_ref) {
  var _data$me;

  var history = _ref.history;

  var _currency = window.localStorage.getItem('currency');

  var currency = _currency ? JSON.parse(_currency) : 'EUR';
  var authToken = getAuthToken();
  var client = new ApolloClient({
    uri: process.env.API_URI,
    headers: {
      Authorization: authToken ? "JWT ".concat(authToken) : null,
      Currency: currency
    }
  });

  var handleProlong = function handleProlong(id) {
    var PROLONG = gql(_templateObject2());
    client.mutate({
      mutation: PROLONG,
      variables: {
        id: id
      }
    }).then(function (resp) {
      location.pathname = "/checkout/address";
    });
  };

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      changedLicense = _React$useState2[0],
      setChangedLicense = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      numberOfDevices = _React$useState4[0],
      setNumberOfDevices = _React$useState4[1];

  var _React$useState5 = React.useState(0),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      numberOfDevicesFinal = _React$useState6[0],
      setNumberOfDevicesFinal = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      changeFromUrlDone = _React$useState8[0],
      setChangeFromUrlDone = _React$useState8[1];

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      data = _useState2[0],
      setData = _useState2[1];

  var handleLicenseChange = function handleLicenseChange(license) {
    setNumberOfDevicesFinal(license.numberOfDevices);
    setChangedLicense(license);
  };

  var handleNumberOfDevicesChange = function handleNumberOfDevicesChange(e) {
    if (changedLicense) {
      var v = parseInt(e.target.value);

      if (changedLicense.numberOfDevices + v >= 4) {
        setNumberOfDevices(v);
        setNumberOfDevicesFinal(changedLicense.numberOfDevices + v);
      }
    }
  };

  var handleChangeSubmit = function handleChangeSubmit() {
    if (numberOfDevicesFinal < 4) numberOfDevicesFinal = (_readOnlyError("numberOfDevicesFinal"), 4);

    if (numberOfDevicesFinal == (changedLicense === null || changedLicense === void 0 ? void 0 : changedLicense.numberOfDevices)) {
      setChangedLicense(null);
      return;
    }

    var CHANGE = gql(_templateObject3());
    client.mutate({
      mutation: CHANGE,
      variables: {
        id: changedLicense === null || changedLicense === void 0 ? void 0 : changedLicense.id,
        numberOfDevices: numberOfDevicesFinal
      }
    }).then(function (resp) {
      if (numberOfDevicesFinal > (changedLicense === null || changedLicense === void 0 ? void 0 : changedLicense.numberOfDevices)) {
        location.pathname = "/checkout/address";
      } else {
        setChangedLicense(null);
        client.query({
          query: licensesByUser
        }).then(function (resp) {
          setData(resp.data);
        });
      }
    });
  };

  if (!data) {
    client.query({
      query: licensesByUser
    }).then(function (resp) {
      setData(resp.data);
    });
  }

  var pathArray = location.pathname.split('/');

  if (changeFromUrlDone == false && changedLicense == null && pathArray.length == 4 && pathArray[2] == 'change') {
    var _uuid = pathArray[3];

    if (data && 'me' in data && data.me && data.me.licenses && data.me.licenses.edges) {
      data.me.licenses.edges.map(function (license) {
        if (license.node.uuid == _uuid) {
          setChangeFromUrlDone(true);
          handleLicenseChange(license.node);
        }
      });
    }
  }

  return !data ? React.createElement(Loader, null) : React.createElement(React.Fragment, null, React.createElement(LicenseTabel, {
    licenses: data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.licenses.edges,
    history: history,
    onProlong: handleProlong,
    onLicenseChange: handleLicenseChange
  }), React.createElement(Modal, {
    title: React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
      id: "@next.pages.Licenses.Licenses.4170181756",
      defaultMessage: "Change number of devices for license"
    }), React.createElement("br", null), changedLicense === null || changedLicense === void 0 ? void 0 : changedLicense.uuid),
    hide: function hide() {
      setChangedLicense(null);
    },
    disabled: false,
    show: changedLicense != null,
    submitBtnText: React.createElement(FormattedMessage, {
      id: "@next.pages.Licenses.Licenses.1473195966",
      defaultMessage: "Change"
    }),
    onSubmit: handleChangeSubmit
  }, React.createElement(S.Block, null, React.createElement(FormattedMessage, {
    id: "@next.pages.Licenses.Licenses.1213016272",
    defaultMessage: "Current number of devices in this license: "
  }), " ", React.createElement(S.Bold, null, changedLicense === null || changedLicense === void 0 ? void 0 : changedLicense.numberOfDevices)), React.createElement(S.Block, null, React.createElement(FormattedMessage, {
    id: "@next.pages.Licenses.Licenses.3099331554",
    defaultMessage: "Add"
  }), React.createElement(S.NumberField, {
    name: "quantity",
    type: "number",
    value: numberOfDevices,
    onChange: handleNumberOfDevicesChange
  }), React.createElement(FormattedMessage, {
    id: "@next.pages.Licenses.Licenses.815630757",
    defaultMessage: "devices to this license"
  })), React.createElement(S.Block, null, React.createElement(FormattedMessage, {
    id: "@next.pages.Licenses.Licenses.3507531314",
    defaultMessage: "Target number of devices: "
  }), " ", React.createElement(S.Bold, null, numberOfDevicesFinal))));
};