import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import Media from "react-media";
import { ProductDescription } from "@components/molecules";
import { ProductAvailableWith } from "@components/organisms";
import { PlaceholderImage } from "@components/atoms";
import AddToCartSection from "@components/organisms/AddToCartSection";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import ImageGallery from 'react-image-gallery';
import { Breadcrumbs, OverlayContext, OverlayTheme, OverlayType } from "../../components";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils";
import OtherProducts from "./Other";
import { structuredData } from "../../core/SEO/Product/structuredData";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: generateCategoryUrl(product.category.id, product.category.name),
    value: product.category.name
  }, {
    link: generateProductUrl(product.id, product.name),
    value: product.name
  }];
};

var Page = function Page(_ref) {
  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var overlayContext = React.useContext(OverlayContext);
  var productGallery = React.useRef();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.find(function (variant) {
        return variant.id === variantId;
      });

      if (variant.images.length > 0) {
        return variant.images;
      }
    }

    return product.images;
  };

  var handleAddToCart = function handleAddToCart(variantId, quantity, components) {
    var cIds = Object.keys(components);
    add(variantId, quantity, cIds.length ? cIds : null);
    overlayContext.show(OverlayType.cart, OverlayTheme.right);
  };

  var isComponent = product.componentOf.length > 0;
  var addToCartSection = React.createElement(AddToCartSection, {
    items: items,
    productId: product.id,
    name: product.name,
    components: product.components,
    componentOf: product.componentOf,
    productVariants: product.variants,
    productPricing: product.pricing,
    queryAttributes: queryAttributes,
    setVariantId: setVariantId,
    variantId: variantId,
    onAddToCart: handleAddToCart,
    onAttributeChangeHandler: onAttributeChangeHandler,
    isAvailableForPurchase: product.isAvailableForPurchase,
    availableForPurchase: product.availableForPurchase,
    isDynfiLicense: product.productType.isDynfiLicense,
    simpleAvailability: product.simpleAvailability
  });
  var images = getImages();
  var galleryImages = [];
  images.map(function (image) {
    galleryImages.push({
      original: image.url,
      thumbnail: image.url
    });
  });

  var imageClicked = function imageClicked() {
    productGallery.current.toggleFullScreen();
  };

  return React.createElement("div", {
    className: "product-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product)
  })), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product"
  }, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(product)), React.createElement(Media, {
    query: {
      maxWidth: mediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product_page__gallery"
    }, galleryImages.length ? React.createElement(ImageGallery, {
      items: galleryImages,
      infinite: false,
      showPlayButton: false,
      showNav: false,
      showFullscreenButton: false
    }) : React.createElement(PlaceholderImage, {
      fullSize: true
    })), React.createElement("div", {
      className: "product-page__product__info"
    }, addToCartSection)) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product-page__product__gallery"
    }, galleryImages.length ? React.createElement(ImageGallery, {
      ref: productGallery,
      items: galleryImages,
      useBrowserFullscreen: false,
      infinite: false,
      showPlayButton: false,
      showNav: false,
      showFullscreenButton: false,
      onClick: imageClicked
    }) : React.createElement("center", null, React.createElement(PlaceholderImage, {
      fullSize: true
    }))), React.createElement("div", {
      className: "product-page__product__info"
    }, React.createElement("div", {
      className: classNames("product-page__product__info--fixed")
    }, addToCartSection)));
  }))), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product__description"
  }, React.createElement(ProductDescription, {
    summaryJson: product.summaryJson,
    descriptionJson: product.descriptionJson,
    attributes: product.attributes,
    components: product.components,
    pdfUrl: product.pdfUrl
  }))), !isComponent ? React.createElement(OtherProducts, {
    products: product.category.products ? product.category.products.edges : []
  }) : React.createElement("div", {
    className: "container"
  }, React.createElement(ProductAvailableWith, {
    products: product.componentOf
  })));
};

export default Page;