import React from "react";
import NoPhoto from "images/no-photo.svg";
export var PlaceholderImage = function PlaceholderImage(_ref) {
  var _ref$alt = _ref.alt,
      alt = _ref$alt === void 0 ? "placeholder" : _ref$alt,
      _ref$fullSize = _ref.fullSize,
      fullSize = _ref$fullSize === void 0 ? false : _ref$fullSize;
  if (fullSize) return React.createElement("img", {
    src: NoPhoto,
    alt: alt,
    style: {
      height: '250px'
    }
  });
  return React.createElement("img", {
    src: NoPhoto,
    alt: alt,
    style: {
      maxHeight: '100px'
    }
  });
};