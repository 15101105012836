import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  padding-top: ", ";\n  padding-left: ", ";\n  width: calc(100% / ", ");\n\n  ", "     \n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  margin-top: ", ";\n  margin-left: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return "-".concat(props.theme.spacing.gutter);
}, function (props) {
  return "-".concat(props.theme.spacing.gutter);
});
export var Tile = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.gutter;
}, function (props) {
  return props.theme.spacing.gutter;
}, function (props) {
  return props.columns;
}, media.smallScreen(_templateObject3()));