import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    query ProxmoxLicense($id: ID!) { \n      proxmoxLicense(id: $id) {\n        id\n        uuid\n        created\n        subscriptionType\n        numberOfCpus\n        subscriptionNumber\n        isPbs\n        expires\n        hasExpired\n      }\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React, { useState } from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";
import { getAuthToken } from "@saleor/sdk";
import { Loader } from "@components/atoms";
import { proxmoxUrl } from "../../../app/routes";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost';
import * as S from "./styles";
var subscriptionTypeMap = {
  'COMMUNITY': 'Community',
  'BASIC': 'Basic',
  'STANDARD': 'Standard',
  'PREMIUM': 'Premium'
};
var cpusMap = {
  'ONE': '1',
  'TWO': '2',
  'FOUR': '4',
  'UNLIMITED': '1-4'
};
export var View = function View(_ref) {
  var id = _ref.match.params.id;
  var intl = useIntl();
  var getProxmoxLicense = gql(_templateObject());
  var authToken = getAuthToken();
  var client = new ApolloClient({
    uri: process.env.API_URI,
    headers: {
      Authorization: authToken ? "JWT ".concat(authToken) : null
    }
  });

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      license = _useState2[0],
      setLicense = _useState2[1];

  if (!license) {
    client.query({
      query: getProxmoxLicense,
      variables: {
        id: id
      }
    }).then(function (resp) {
      setLicense(resp.data.proxmoxLicense);
    });
  }

  var created = new Date(license === null || license === void 0 ? void 0 : license.created);
  var expires = new Date(license === null || license === void 0 ? void 0 : license.expires);
  return !license ? React.createElement(Loader, null) : React.createElement("div", {
    className: "order-details container"
  }, React.createElement(Link, {
    className: "order-details__link",
    to: proxmoxUrl
  }, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.3160754622",
    defaultMessage: "Go back to Licenses List"
  })), React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.4206032895",
    defaultMessage: "License"
  }), " ", license === null || license === void 0 ? void 0 : license.uuid), React.createElement(S.Wrapper, null, React.createElement(S.Row, null, React.createElement(S.HeaderRow, null, React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.3508413817",
    defaultMessage: "Created"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.421686156",
    defaultMessage: "Subscription type"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.3742516100",
    defaultMessage: "Number of CPU sockets"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.766124952",
    defaultMessage: "Subscription number"
  })), React.createElement(S.DateField, null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.1097251999",
    defaultMessage: "Expires"
  })))), React.createElement(S.Row, null, React.createElement(S.DateField, null, React.createElement(FormattedDate, {
    value: created
  })), React.createElement(S.Value, null, subscriptionTypeMap[license.subscriptionType]), React.createElement(S.Value, null, cpusMap[license.numberOfCpus]), React.createElement(S.Value, null, license.subscriptionNumber), React.createElement(S.DateField, null, React.createElement(FormattedDate, {
    value: expires
  }), React.createElement("br", null), license.hasExpired ? React.createElement("button", null, React.createElement(FormattedMessage, {
    id: "proxmoxDetails.views.ProxmoxDetails.View.2717763435",
    defaultMessage: "Expired"
  })) : null))));
};
export default View;