import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from 'react-flags-select';
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAuth, useCart } from "@saleor/sdk";
import useLocale from "@hooks/useLocale";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { DemoBanner } from "@components/atoms";
import classNames from "classnames";
import { MenuDropdown, Offline, Online, OverlayContext, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import NavDropdown from "./NavDropdown";
import { TypedMainMenuQuery } from "./queries";
import cartImg from "../../images/cart.svg";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import logoImg from "../../images/logo.svg";
import searchImg from "../../images/search.svg";
import userImg from "../../images/user.svg";
import emailImg from "../../images/email.svg";
import phoneImg from "../../images/phone.svg";
import { mediumScreen, smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

var MainMenu = function MainMenu(_ref) {
  var demoMode = _ref.demoMode;
  var overlayContext = useContext(OverlayContext);

  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useCart = useCart(),
      items = _useCart.items;

  var handleSignOut = function handleSignOut() {
    signOut();
  };

  var cartItemsQuantity = 0;

  for (var i in items) {
    var _item$variant, _item$variant$product;

    var item = items[i];
    var dfl = false;

    if (item === null || item === void 0 ? void 0 : (_item$variant = item.variant) === null || _item$variant === void 0 ? void 0 : (_item$variant$product = _item$variant.product) === null || _item$variant$product === void 0 ? void 0 : _item$variant$product.productType) {
      var _item$variant2, _item$variant2$produc, _item$variant2$produc2;

      dfl = (item === null || item === void 0 ? void 0 : (_item$variant2 = item.variant) === null || _item$variant2 === void 0 ? void 0 : (_item$variant2$produc = _item$variant2.product) === null || _item$variant2$produc === void 0 ? void 0 : (_item$variant2$produc2 = _item$variant2$produc.productType) === null || _item$variant2$produc2 === void 0 ? void 0 : _item$variant2$produc2.isShippingRequired) == false;
    }

    cartItemsQuantity += dfl ? 1 : item.quantity;
  }

  var _useState = useState(undefined),
      _useState2 = _slicedToArray(_useState, 2),
      activeDropdown = _useState2[0],
      setActiveDropdown = _useState2[1];

  useEffect(function () {
    if (activeDropdown) {
      overlayContext.show(OverlayType.mainMenuNav, OverlayTheme.modal);
    } else {
      overlayContext.hide();
    }
  }, [activeDropdown]);

  var showDropdownHandler = function showDropdownHandler(itemId, hasSubNavigation) {
    if (hasSubNavigation) {
      setActiveDropdown(itemId);
    }
  };

  var hideDropdownHandler = function hideDropdownHandler() {
    if (activeDropdown) {
      setActiveDropdown(undefined);
    }
  };

  var _useLocale = useLocale(),
      locale = _useLocale.locale,
      setLocale = _useLocale.setLocale,
      currency = _useLocale.currency,
      setCurrency = _useLocale.setCurrency;

  var selectedFlag = locale.toUpperCase().replace('EN', 'GB');

  var setSelectedFlag = function setSelectedFlag(code) {
    var l = code.toLowerCase().replace('gb', 'en');
    setLocale(l);
  };

  var changeCurrency = function changeCurrency(c) {
    setCurrency(c);
  };

  var currencySign = currency == 'USD' ? '$' : '€';
  return React.createElement("header", {
    className: classNames({
      "header-with-dropdown": !!activeDropdown,
      "main-menu__header": true
    })
  }, demoMode && React.createElement(DemoBanner, null), React.createElement("nav", {
    className: "main-menu",
    id: "header"
  }, React.createElement("div", {
    className: "main-menu__left"
  }, React.createElement(Link, {
    to: appPaths.baseUrl
  }, React.createElement(ReactSVG, {
    path: logoImg
  }))), React.createElement("div", {
    className: "main-menu__center",
    style: {
      'whiteSpace': 'nowrap'
    }
  }, React.createElement(TypedMainMenuQuery, {
    renderOnError: true,
    displayLoader: false
  }, function (_ref2) {
    var data = _ref2.data;
    var items = maybe(function () {
      return data.shop.navigation.main.items;
    }, []);
    if (!items.length) return React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return React.createElement(React.Fragment, null, React.createElement("div", {
          className: "contact-item"
        }, React.createElement(ReactSVG, {
          path: emailImg
        }), " ", React.createElement("a", {
          href: "mailto:info@dynfi.com"
        }, "info@dynfi.com")), React.createElement("div", {
          className: "contact-item"
        }, React.createElement(ReactSVG, {
          path: phoneImg
        }), " ", React.createElement("a", {
          href: "tel:33184751234"
        }, "+33 1 84 75 1234")));
      }
    });
    return React.createElement("ul", null, React.createElement(Media, {
      query: {
        maxWidth: smallScreen
      },
      render: function render() {
        return React.createElement("li", {
          "data-test": "toggleSideMenuLink",
          className: "main-menu__hamburger",
          onClick: function onClick() {
            return overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
              data: items
            });
          }
        }, React.createElement(ReactSVG, {
          path: hamburgerImg,
          className: "main-menu__hamburger--icon"
        }), React.createElement(ReactSVG, {
          path: hamburgerHoverImg,
          className: "main-menu__hamburger--hover"
        }));
      }
    }), React.createElement(Media, {
      query: {
        minWidth: mediumScreen
      },
      render: function render() {
        return items.map(function (item) {
          var _item$children;

          var hasSubNavigation = !!(item === null || item === void 0 ? void 0 : (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length);
          return React.createElement("li", {
            "data-test": "mainMenuItem",
            className: "main-menu__item",
            key: item.id
          }, React.createElement(NavDropdown, _extends({
            overlay: overlayContext,
            showDropdown: activeDropdown === item.id && hasSubNavigation,
            onShowDropdown: function onShowDropdown() {
              return showDropdownHandler(item.id, hasSubNavigation);
            },
            onHideDropdown: hideDropdownHandler
          }, item)));
        });
      }
    }));
  })), React.createElement("div", {
    className: "main-menu__right"
  }, React.createElement("ul", null, React.createElement(Online, null, React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    },
    render: function render() {
      return React.createElement(React.Fragment, null, user ? React.createElement(MenuDropdown, {
        suffixClass: "__rightdown",
        head: React.createElement("li", {
          className: "main-menu__icon main-menu__user--active"
        }, React.createElement(ReactSVG, {
          path: userImg
        })),
        content: React.createElement("ul", {
          className: "main-menu__dropdown"
        }, React.createElement("li", {
          "data-test": "mobileMenuMyAccountLink"
        }, React.createElement(Link, {
          to: appPaths.accountUrl
        }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
          "data-test": "mobileMenuOrderHistoryLink"
        }, React.createElement(Link, {
          to: appPaths.orderHistoryUrl
        }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
          "data-test": "mobileMenuLicensesLink"
        }, React.createElement(Link, {
          to: appPaths.licensesUrl
        }, "DynFi licenses")), React.createElement("li", {
          "data-test": "mobileMenuProxmoxLink"
        }, React.createElement(Link, {
          to: appPaths.proxmoxUrl
        }, "Proxmox licenses")), React.createElement("li", {
          "data-test": "mobileMenuAddressBookLink"
        }, React.createElement(Link, {
          to: appPaths.addressBookUrl
        }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
          "data-test": "mobileMenuStoredCardsLink"
        }, React.createElement(Link, {
          to: appPaths.storedCardsUrl
        }, "Stored Cards")), React.createElement("li", {
          "data-test": "mobileMenuAcceptedContractsLink"
        }, React.createElement(Link, {
          to: appPaths.acceptedContractsUrl
        }, "Contracts")), React.createElement("li", {
          onClick: handleSignOut,
          "data-test": "mobileMenuLogoutLink"
        }, React.createElement(FormattedMessage, commonMessages.logOut)))
      }) : React.createElement("li", {
        "data-test": "mobileMenuLoginLink",
        className: "main-menu__icon",
        onClick: function onClick() {
          return overlayContext.show(OverlayType.login, OverlayTheme.left);
        }
      }, React.createElement(ReactSVG, {
        path: userImg
      })));
    }
  }), React.createElement(Media, {
    query: {
      minWidth: smallScreen
    },
    render: function render() {
      return React.createElement(React.Fragment, null, user ? React.createElement(MenuDropdown, {
        head: React.createElement("li", {
          className: "main-menu__icon main-menu__user--active"
        }, React.createElement("span", null, React.createElement(ReactSVG, {
          path: userImg
        }), " ", React.createElement("p", null, user.firstName.length || user.lastName.length ? [user.firstName, user.lastName].join(' ') : user.email.split('@').shift()))),
        content: React.createElement("ul", {
          className: "main-menu__dropdown"
        }, React.createElement("li", {
          "data-test": "desktopMenuMyAccountLink"
        }, React.createElement(Link, {
          to: appPaths.accountUrl
        }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
          "data-test": "desktopMenuOrderHistoryLink"
        }, React.createElement(Link, {
          to: appPaths.orderHistoryUrl
        }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
          "data-test": "desktopMenuLicensesLink"
        }, React.createElement(Link, {
          to: appPaths.licensesUrl
        }, "DynFi licenses")), React.createElement("li", {
          "data-test": "mobileMenuProxmoxLink"
        }, React.createElement(Link, {
          to: appPaths.proxmoxUrl
        }, "Proxmox licenses")), React.createElement("li", {
          "data-test": "desktopMenuAddressBookLink"
        }, React.createElement(Link, {
          to: appPaths.addressBookUrl
        }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
          "data-test": "desktopMenuStoredCardsLink"
        }, React.createElement(Link, {
          to: appPaths.storedCardsUrl
        }, React.createElement(FormattedMessage, commonMessages.storedCards))), React.createElement("li", {
          "data-test": "desktopMenuAcceptedContractsLink"
        }, React.createElement(Link, {
          to: appPaths.acceptedContractsUrl
        }, React.createElement(FormattedMessage, commonMessages.acceptedContracts))), React.createElement("li", {
          onClick: handleSignOut,
          "data-test": "desktopMenuLogoutLink"
        }, React.createElement(FormattedMessage, commonMessages.logOut)))
      }) : React.createElement("li", {
        "data-test": "desktopMenuLoginOverlayLink",
        className: "main-menu__icon",
        onClick: function onClick() {
          return overlayContext.show(OverlayType.login, OverlayTheme.right);
        }
      }, React.createElement("span", null, React.createElement(ReactSVG, {
        path: userImg
      }), " ", React.createElement("p", null, React.createElement(FormattedMessage, commonMessages.account)))));
    }
  }), React.createElement("li", {
    "data-test": "menuCartOverlayLink",
    className: "main-menu__icon main-menu__cart",
    onClick: function onClick() {
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
    }
  }, React.createElement(Media, {
    query: {
      minWidth: mediumScreen
    },
    render: function render() {
      return React.createElement("span", null, React.createElement(ReactSVG, {
        path: cartImg
      }), cartItemsQuantity > 0 ? React.createElement("span", {
        className: "main-menu__cart__quantity"
      }, cartItemsQuantity) : null);
    }
  }), React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    },
    render: function render() {
      return React.createElement("span", null, React.createElement(ReactSVG, {
        path: cartImg
      }), cartItemsQuantity > 0 ? React.createElement("span", {
        className: "main-menu__cart__quantity"
      }, cartItemsQuantity) : null);
    }
  })), React.createElement("li", {
    className: "main-menu__currency"
  }, React.createElement(MenuDropdown, {
    head: React.createElement("li", {
      className: "main-menu__icon currency-sign"
    }, React.createElement("span", null, currencySign)),
    content: React.createElement("ul", {
      className: "main-menu__dropdown"
    }, React.createElement("li", null, React.createElement(Link, {
      onClick: function onClick() {
        return changeCurrency('EUR');
      }
    }, "EUR")), React.createElement("li", null, React.createElement(Link, {
      onClick: function onClick() {
        return changeCurrency('USD');
      }
    }, "USD")))
  })), React.createElement("li", {
    "data-test": "menuFlagOverlayLink",
    className: "main-menu__flag"
  }, React.createElement(ReactFlagsSelect, {
    selected: selectedFlag,
    countries: ["GB", "FR", "DE"],
    showSelectedLabel: false,
    showSecondarySelectedLabel: false,
    showOptionLabel: false,
    showSecondaryOptionLabel: false,
    selectedSize: 24,
    optionsSize: 24,
    onSelect: function onSelect(code) {
      return setSelectedFlag(code);
    }
  }))), React.createElement(Offline, null, React.createElement("li", {
    className: "main-menu__offline"
  }, React.createElement(Media, {
    query: {
      minWidth: mediumScreen
    },
    render: function render() {
      return React.createElement("span", null, React.createElement(FormattedMessage, {
        id: "components.MainMenu.MainMenu.4144939532",
        defaultMessage: "Offline"
      }));
    }
  }))), React.createElement("li", {
    "data-test": "menuSearchOverlayLink",
    className: "main-menu__search",
    onClick: function onClick() {
      return overlayContext.show(OverlayType.search, OverlayTheme.right);
    }
  }, React.createElement(ReactSVG, {
    path: searchImg
  }), React.createElement(Media, {
    query: {
      minWidth: mediumScreen
    },
    render: function render() {
      return React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.search));
    }
  }))))));
};

export default MainMenu;