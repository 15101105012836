import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import pdfPng from '../../../../images/pdf.png';
export var ProductPdf = function ProductPdf(_ref) {
  var _ref$pdfUrl = _ref.pdfUrl,
      pdfUrl = _ref$pdfUrl === void 0 ? "" : _ref$pdfUrl;
  if (!pdfUrl || !pdfUrl.length) return null;
  return React.createElement(S.Wrapper, null, React.createElement("a", {
    href: pdfUrl,
    target: "_blank"
  }, React.createElement(S.Line, null, React.createElement("img", {
    src: pdfPng,
    alt: "PDF",
    width: 48,
    height: 48
  })), React.createElement(S.Line, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductPdf.ProductPdf.2100718597",
    defaultMessage: "Download technical specification PDF"
  }))));
};