import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
export var Money = function Money(_ref) {
  var money = _ref.money,
      defaultValue = _ref.defaultValue,
      _ref$round = _ref.round,
      round = _ref$round === void 0 ? true : _ref$round,
      props = _objectWithoutProperties(_ref, ["money", "defaultValue", "round"]);

  if (!money) {
    return React.createElement("span", props, defaultValue);
  }

  var m = money.currency && money.currency !== "" ? money.amount.toLocaleString("en-US", {
    currency: money.currency,
    style: "currency"
  }) : money.amount.toString();
  if (round) m = m.replace('.00', '');
  return React.createElement("span", props, m);
};
Money.displayName = "Money";
export default Money;