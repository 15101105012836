import "./scss/index.scss";
import * as React from "react";
import Nav from "./Nav";

var Footer = function Footer() {
  return React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement(Nav, null), React.createElement("div", {
    className: "footer__veryend"
  }, "\xA9 2021 DynFi"));
};

export default Footer;