import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Attribute, IconButton, Tile } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { useAccountUpdate, useAuth, useCheckout } from "@saleor/sdk";
import { CurrencyChangeForm } from "./CurrencyChangeForm";
import * as S from "./styles";
export var CurrencyTile = function CurrencyTile() {
  var _extraInfo;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isEditing = _React$useState2[0],
      setIsEditing = _React$useState2[1];

  var _useAccountUpdate = useAccountUpdate(),
      _useAccountUpdate2 = _slicedToArray(_useAccountUpdate, 2),
      setAccountUpdate = _useAccountUpdate2[0],
      _useAccountUpdate2$ = _useAccountUpdate2[1],
      data = _useAccountUpdate2$.data,
      error = _useAccountUpdate2$.error;

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      setBillingAddress = _useCheckout.setBillingAddress;

  React.useEffect(function () {
    if (data && !error) {
      setIsEditing(false);
    }
  }, [data, error]);
  return React.createElement(S.TileWrapper, null, React.createElement(Tile, null, React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.CurrencyTile.3354183532",
    defaultMessage: "MY CURRENCY"
  }), !isEditing && React.createElement(IconButton, {
    testingContext: "editCurrencyButton",
    name: "edit",
    size: 22,
    onClick: function onClick() {
      return setIsEditing(function (isEditing) {
        return !isEditing;
      });
    }
  })), React.createElement(S.Content, null, isEditing ? React.createElement(S.ContentEdit, null, React.createElement(CurrencyChangeForm, {
    initial: user.currency || "",
    handleSubmit: function handleSubmit(data) {
      setAccountUpdate({
        input: data
      });
    },
    hide: function hide() {
      setIsEditing(false);
    },
    error: error ? (_extraInfo = error.extraInfo) === null || _extraInfo === void 0 ? void 0 : _extraInfo.userInputErrors : []
  })) : React.createElement(Attribute, {
    description: intl.formatMessage(commonMessages.preferredCurrency),
    attributeValue: user.currency || "---",
    testingContext: "currencyText"
  })))));
};