import { LocaleContext } from "../../components/Locale";
import { useContext } from "react";

function useLocale() {
  var localeInfo = useContext(LocaleContext);
  if (!localeInfo.locale) localeInfo.locale = 'en';
  if (!localeInfo.currency) localeInfo.currency = 'EUR';
  return localeInfo;
}

export default useLocale;