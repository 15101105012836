import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { generateProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg";

var ProductList = function ProductList(_ref) {
  var lines = _ref.lines,
      remove = _ref.remove;
  return React.createElement("ul", {
    className: "cart__list"
  }, lines.map(function (line, index) {
    var _line$variant, _line$variant$product, _line$variant$product2;

    var productUrl = generateProductUrl(line.variant.product.id, line.variant.product.name);
    var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);
    return React.createElement("li", {
      key: key,
      className: "cart__list__item",
      "data-test": "cartRow",
      "data-test-id": line.variant.sku
    }, React.createElement(Link, {
      to: productUrl
    }, React.createElement(Thumbnail, {
      source: line.variant.product
    })), React.createElement("br", null), React.createElement("div", {
      className: "cart__list__item__details"
    }, React.createElement(Link, {
      to: productUrl
    }, React.createElement("p", {
      className: "name",
      "data-test": "name"
    }, line.variant.product.name)), React.createElement("p", {
      className: "price",
      "data-test": "price"
    }, React.createElement(TaxedMoney, {
      taxedMoney: line.variant.pricing.price,
      showBothPrices: true,
      grossFirst: true
    })), React.createElement("span", {
      className: "cart__list__item__details__variant"
    }, line.variant.name), React.createElement("span", {
      className: "bottom"
    }, React.createElement("span", {
      "data-test": "quantity"
    }, (line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : (_line$variant$product = _line$variant.product) === null || _line$variant$product === void 0 ? void 0 : (_line$variant$product2 = _line$variant$product.productType) === null || _line$variant$product2 === void 0 ? void 0 : _line$variant$product2.isShippingRequired) ? React.createElement(FormattedMessage, {
      id: "components.OverlayManager.Cart.ProductList.3765570239",
      defaultMessage: "Quantity: {quantity}",
      values: {
        quantity: line.quantity
      }
    }) : React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
      id: "components.OverlayManager.Cart.ProductList.200901494",
      defaultMessage: "Number of devices"
    }), " ", line.quantity)), React.createElement(ReactSVG, {
      path: removeImg,
      className: "cart__list__item__details__delete-icon",
      "data-test": "deleteButton",
      onClick: function onClick() {
        return remove(line.variant.id);
      }
    }))));
  }));
};

export default ProductList;