import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ProductTile } from "@components/molecules";
import * as S from "./styles";
import { generateProductUrl } from "../../../../core/utils";
export var ProductAvailableWith = function ProductAvailableWith(_ref) {
  var products = _ref.products;
  return React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ProductAvailableWith.ProductAvailableWith.3045008441",
    defaultMessage: "Available with"
  })), React.createElement(S.List, null, products.map(function (product) {
    return React.createElement(Link, {
      to: generateProductUrl(product.id, product.name),
      key: product.id
    }, React.createElement(ProductTile, {
      product: product
    }));
  })));
};