import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { IconButton, Tile } from "@components/atoms/";
import { CreditCardNumberWithIcon } from "../CreditCardNumberWithIcon";
import * as S from "./styles";
export var CreditCardTile = function CreditCardTile(_ref) {
  var id = _ref.id,
      brand = _ref.brand,
      expMonth = _ref.expMonth,
      expYear = _ref.expYear,
      lastDigits = _ref.lastDigits,
      onRemove = _ref.onRemove,
      onClick = _ref.onClick,
      markOnClick = _ref.markOnClick;
  var header = React.createElement(CreditCardNumberWithIcon, {
    last4Digits: lastDigits
  }); // creditCardProvider={creditCardProvider}

  var content = React.createElement(React.Fragment, null, React.createElement(S.BoldTitle, null, "Expires on"), React.createElement(S.TextContent, {
    "data-test": "expirationDate"
  }, expMonth, "/", expYear));
  var footer = React.createElement(S.FooterContent, null, React.createElement("div", null, React.createElement(IconButton, {
    name: "trash",
    onClick: onRemove,
    size: 22,
    testingContext: "removeCardButton"
  }))); //      footer={footer}

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      marked = _useState2[0],
      setMarked = _useState2[1];

  var handleClick = function handleClick() {
    if (markOnClick) {
      setMarked(!marked);
    }

    if (onClick) onClick({
      id: id,
      creditCardInfo: {
        brand: brand,
        expMonth: expMonth,
        expYear: expYear,
        firstDigits: null,
        lastDigits: lastDigits
      }
    });
  };

  return React.createElement(Tile, {
    header: header,
    onClick: handleClick,
    "data-test": "creditCardTile",
    "data-test-id": lastDigits,
    tileType: marked ? 'selected' : '',
    style: {
      backgroundColor: 'rgba(255, 255, 255, .5)'
    }
  }, content);
};