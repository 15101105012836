import React from "react";
import { Thumbnail } from "@components/molecules";
import { RichTextContent } from "@components/atoms";
import * as S from "./styles";
export var ProductComponents = function ProductComponents(_ref) {
  var components = _ref.components;
  return React.createElement(S.Wrapper, null, components.map(function (component) {
    var _component$product, _component$product2, _component$product3;

    var name = ((_component$product = component.product) === null || _component$product === void 0 ? void 0 : _component$product.name) || component.name || component.sku;
    var image = (_component$product2 = component.product) === null || _component$product2 === void 0 ? void 0 : _component$product2.images;
    return React.createElement(S.Item, {
      key: component.sku
    }, React.createElement(S.Header, null, React.createElement(S.Image, null, React.createElement(Thumbnail, {
      source: component.product
    })), React.createElement(S.Title, null, name)), React.createElement(S.Description, null, React.createElement(RichTextContent, {
      descriptionJson: component === null || component === void 0 ? void 0 : (_component$product3 = component.product) === null || _component$product3 === void 0 ? void 0 : _component$product3.descriptionJson
    })));
  }));
};