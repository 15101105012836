import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { Money } from "../Money";
import { FormattedMessage } from "react-intl";
import { ShopContext } from "../../../../components/ShopProvider/context";
export var TaxedMoney = function TaxedMoney(_ref) {
  var taxedMoney = _ref.taxedMoney,
      defaultValue = _ref.defaultValue,
      showBothPrices = _ref.showBothPrices,
      displayExcVTA = _ref.displayExcVTA,
      grossFirst = _ref.grossFirst,
      props = _objectWithoutProperties(_ref, ["taxedMoney", "defaultValue", "showBothPrices", "displayExcVTA", "grossFirst"]);

  var _React$useContext = React.useContext(ShopContext),
      displayGrossPrices = _React$useContext.displayGrossPrices;

  var money = taxedMoney ? displayGrossPrices ? taxedMoney.gross : taxedMoney.net : undefined;
  if (!taxedMoney) return null;

  if (taxedMoney.gross.amount == taxedMoney.net.amount) {
    if (showBothPrices) {
      return React.createElement(React.Fragment, null, React.createElement(Money, _extends({}, props, {
        money: taxedMoney.net,
        defaultValue: defaultValue
      })), " ", React.createElement("small", null, " ", React.createElement(FormattedMessage, {
        id: "@next.components.containers.TaxedMoney.TaxedMoney.3273588244",
        defaultMessage: "Exc. VAT"
      })));
    } else {
      return React.createElement(React.Fragment, null, React.createElement(Money, _extends({}, props, {
        money: taxedMoney.net,
        defaultValue: defaultValue
      })));
    }
  } else if (!showBothPrices) {
    if (displayExcVTA) {
      return React.createElement(React.Fragment, null, React.createElement(Money, _extends({}, props, {
        money: taxedMoney.net,
        defaultValue: defaultValue
      })), " ", React.createElement("small", null, " ", React.createElement(FormattedMessage, {
        id: "@next.components.containers.TaxedMoney.TaxedMoney.3273588244",
        defaultMessage: "Exc. VAT"
      })));
    } else {
      return React.createElement(React.Fragment, null, React.createElement(Money, _extends({}, props, {
        money: taxedMoney.net,
        defaultValue: defaultValue
      })));
    }
  }

  if (grossFirst) {
    return React.createElement(React.Fragment, null, React.createElement("span", {
      className: "price_gross"
    }, React.createElement(Money, _extends({}, props, {
      money: taxedMoney.gross,
      defaultValue: defaultValue
    })), "\xA0", React.createElement("small", null, React.createElement(FormattedMessage, {
      id: "@next.components.containers.TaxedMoney.TaxedMoney.969020158",
      defaultMessage: "Inc. VAT"
    }))), React.createElement("span", {
      className: "price_net"
    }, React.createElement(Money, _extends({}, props, {
      money: taxedMoney.net,
      defaultValue: defaultValue
    })), "\xA0", React.createElement("small", null, React.createElement(FormattedMessage, {
      id: "@next.components.containers.TaxedMoney.TaxedMoney.3273588244",
      defaultMessage: "Exc. VAT"
    }))));
  }

  return React.createElement(React.Fragment, null, React.createElement("span", {
    className: "price_net"
  }, React.createElement(Money, _extends({}, props, {
    money: taxedMoney.net,
    defaultValue: defaultValue
  })), "\xA0", React.createElement("small", null, React.createElement(FormattedMessage, {
    id: "@next.components.containers.TaxedMoney.TaxedMoney.3273588244",
    defaultMessage: "Exc. VAT"
  }))), React.createElement("span", {
    className: "price_gross"
  }, React.createElement(Money, _extends({}, props, {
    money: taxedMoney.gross,
    defaultValue: defaultValue
  })), "\xA0", React.createElement("small", null, React.createElement(FormattedMessage, {
    id: "@next.components.containers.TaxedMoney.TaxedMoney.969020158",
    defaultMessage: "Inc. VAT"
  }))));
};
TaxedMoney.displayName = "TaxedMoney";
export default TaxedMoney;