import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: 500;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-align: right;\n  font-size: 1.3rem;\n  color: ", ";\n  font-weight: 600;\n\n  .price_gross {\n    color: ", ";\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n  .price_net {\n    display: block;\n    font-size: 100%;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.25rem;\n  color: ", ";\n  font-weight: 600;\n  line-height: 1.5rem;\n  text-transform: uppercase;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: 500;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: min-content;\n  margin-bottom: 10px;\n  img {\n    height: auto;\n    max-width: 100px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: contents;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Photo = styled.div(_templateObject2());
export var Sku = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var Name = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.secondaryDark;
});
export var Price = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var Quantity = styled.div(_templateObject6(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});