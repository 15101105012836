import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import "./scss/index.scss";

var Nav = function Nav() {
  var intl = useIntl();
  return React.createElement("footer", {
    className: "footer-nav"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "footer-nav__section"
  }, React.createElement("h4", {
    className: "footer-nav__section-header"
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.2968663655",
    defaultMessage: "Products"
  }))), React.createElement("div", {
    className: "footer-nav__section-content"
  }, React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlDemo)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.361402403",
    defaultMessage: "Request demo access"
  }))), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlShop)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.193168060",
    defaultMessage: "Our appliances"
  }))), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlDFM)
  }, "DynFi Manager")), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlDFF)
  }, "DynFi Firewall")))), React.createElement("div", {
    className: "footer-nav__section"
  }, React.createElement("h4", {
    className: "footer-nav__section-header"
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.3570415321",
    defaultMessage: "Company"
  }))), React.createElement("div", {
    className: "footer-nav__section-content"
  }, React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlCompany)
  }, "DynFi")), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlPartner)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.4257972903",
    defaultMessage: "Our partners"
  }))), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlPress)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.4131598919",
    defaultMessage: "Press"
  }))))), React.createElement("div", {
    className: "footer-nav__section"
  }, React.createElement("h4", {
    className: "footer-nav__section-header"
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.3945100864",
    defaultMessage: "Services"
  }))), React.createElement("div", {
    className: "footer-nav__section-content"
  }, React.createElement("p", null, React.createElement("a", {
    href: "https://dynfi.com/forum/"
  }, "Forum")), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlDoc)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.2840203410",
    defaultMessage: "Knowledge base"
  }))), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlDlDFM)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.1504964842",
    defaultMessage: "Download DynFi Manager"
  }))), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlDlDFF)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.1558844994",
    defaultMessage: "Download DynFi Firewall"
  }))))), React.createElement("div", {
    className: "footer-nav__section"
  }, React.createElement("h4", {
    className: "footer-nav__section-header"
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.870402703",
    defaultMessage: "Keep in touch"
  }))), React.createElement("div", {
    className: "footer-nav__section-content"
  }, React.createElement("p", null, React.createElement("a", {
    href: "https://twitter.com/dynfi"
  }, "Twitter")), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlNewsletter)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.443435199",
    defaultMessage: "Subscribe to newsletter"
  }))), React.createElement("p", null, React.createElement("a", {
    href: intl.formatMessage(commonMessages.footerUrlContact)
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.1187718370",
    defaultMessage: "Contact us"
  })))))));
};

export default Nav;