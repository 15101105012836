import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding-top: 10px;\n  padding-bottom: 10px;\n  border-top: 1px solid ", ";\n  text-align: left;\n  text-transform: capitalize;\n\n  span {\n    font-weight: bold;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: auto;\n  max-width: 100%;\n\n  > img {\n    width: auto;\n    height: auto;\n    max-width: 100%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  font-size: 22px;\n  color: ", ";\n  font-size: 160%;\n  font-weight: bold;\n  padding-top: 20px;\n  position: relative;\n  top: 0px;\n\n  .price_gross {\n    display: block;\n    white-space: nowrap;    \n    color: ", ";\n    font-weight: normal;\n    font-size: 75%;\n  }\n  \n  .price_net {    \n    display: block;\n    white-space: nowrap;\n    margin-top: 2px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  ", "\n  text-align: center;\n  color: ", ";\n  font-size: 160%;\n  margin: 0;\n  line-height: 1.75rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.8rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  background-image: linear-gradient(0deg, #FFFFFF 66%, #C6CFDD 100%);  \n  border-radius: 10px;\n  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.43);\n  padding: 2rem;\n  text-align: center;\n  transition: 0.3s;\n  padding-top: 0;\n  height: 500px;\n\n  :hover {\n    background-color: ", ";\n    background-image: linear-gradient(0deg, ", " 66%, ", " 100%);\n    \n    h4 {\n      color: ", ";\n    }\n\n    * {\n      ", "\n    }\n  }\n\n  ", "    \n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";  \n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
var hoveredTextProps = css(_templateObject2(), function (props) {
  return props.theme.colors.white;
});
export var Wrapper = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.white;
}, hoveredTextProps, media.largeScreen(_templateObject4()));
export var Title = styled.h4(_templateObject5(), textProps, function (props) {
  return props.theme.colors.secondaryDark;
});
export var Price = styled.p(_templateObject6(), textProps, function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var Image = styled.div(_templateObject7());
export var Detail = styled.p(_templateObject8(), function (props) {
  return props.theme.colors.divider;
});