import "./scss/index.scss";
import * as React from "react";
import useLocale from "@hooks/useLocale";
import { MetaWrapper } from "../../components";
import Page from "./Page";
import { TypedHomePageQuery } from "./queries";

var View = function View() {
  var _useLocale = useLocale(),
      locale = _useLocale.locale;

  var applyTranslation = function applyTranslation(languageCode, obj) {
    var trans = obj['trans' + languageCode];

    if (trans) {
      for (var p in trans) {
        if (trans[p] && trans[p].length) obj[p] = trans[p];
      }
    }
  };

  return React.createElement("div", {
    className: "home-page"
  }, React.createElement(TypedHomePageQuery, {
    alwaysRender: true,
    displayLoader: false,
    errorPolicy: "all"
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;

    if (locale != 'en') {
      var languageCode = locale.toUpperCase();

      if (data) {
        if ('categories' in data && data.categories && 'edges' in data.categories && data.categories.edges) {
          for (var i in data.categories.edges) {
            applyTranslation(languageCode, data.categories.edges[i].node);
          }
        }

        if ('shop' in data && data.shop) {
          applyTranslation(languageCode, data.shop);
        }
      }
    }

    return React.createElement(MetaWrapper, {
      meta: {
        description: data.shop ? data.shop.description : "",
        title: data.shop ? data.shop.headerText : ""
      }
    }, React.createElement(Page, {
      loading: loading,
      backgroundImage: data.shop && data.shop.homepageCollection && data.shop.homepageCollection.backgroundImage,
      categories: data.categories,
      shop: data.shop
    }));
  }));
};

export default View;