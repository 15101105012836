import React from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { TaxedMoney } from "@components/containers";
import { commonMessages, translateOrderStatus } from "@temp/intl";
import { DropdownMenu, IconButton } from "@components/atoms";
import { Thumbnail } from "..";
import * as S from "./styles";
var API_URL = process.env.API_URI;

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.2387666633",
    defaultMessage: "Index Number"
  })), matches && React.createElement(React.Fragment, null, React.createElement(S.ProductsOrdered, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.724329054",
    defaultMessage: "Products Ordered"
  })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.1633831221",
    defaultMessage: "Date of Order"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.1148029984",
    defaultMessage: "Value"
  }))), React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)), React.createElement(S.Actions, null));
};

export var OrderTabel = function OrderTabel(_ref) {
  var orders = _ref.orders,
      history = _ref.history;
  var theme = React.useContext(ThemeContext);
  var intl = useIntl();

  var downloadInvoice = function downloadInvoice(order) {
    var _order$invoices;

    if (order && "invoices" in order && ((_order$invoices = order.invoices) === null || _order$invoices === void 0 ? void 0 : _order$invoices.length) > 0) {
      var invoice = order.invoices.reduce(function (a, b) {
        return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
      });

      if (invoice) {
        var invoiceUrl = API_URL.replace('/graphql', '/media') + invoice.url.split('/media').pop();
        window.open(invoiceUrl, "_blank");
      }
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches)), orders && orders.map(function (order) {
      var date = new Date(order.node.created);
      return React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: order.node.number
      }, React.createElement(S.IndexNumber, {
        onClick: function onClick(evt) {
          evt.stopPropagation();
          history.push("/order-history/".concat(order.node.token));
        }
      }, order.node.number), matches ? React.createElement(React.Fragment, null, React.createElement(S.ProductsOrdered, null, order.node.lines.slice(0, 5).map(function (product) {
        var _product$variant;

        return React.createElement("span", {
          key: (_product$variant = product.variant) === null || _product$variant === void 0 ? void 0 : _product$variant.product.id,
          onClick: function onClick(evt) {
            evt.stopPropagation();
            history.push("/order-history/".concat(order.node.token));
          }
        }, React.createElement(Thumbnail, {
          source: product
        }));
      })), React.createElement(S.DateOfOrder, {
        onClick: function onClick(evt) {
          evt.stopPropagation();
          history.push("/order-history/".concat(order.node.token));
        }
      }, React.createElement(FormattedDate, {
        value: date
      })), React.createElement(S.Value, {
        onClick: function onClick(evt) {
          evt.stopPropagation();
          history.push("/order-history/".concat(order.node.token));
        }
      }, React.createElement(TaxedMoney, {
        taxedMoney: order.node.total
      }))) : "", React.createElement(S.Status, {
        onClick: function onClick(evt) {
          evt.stopPropagation();
          history.push("/order-history/".concat(order.node.token));
        }
      }, translateOrderStatus(order.node.statusDisplay, intl)), React.createElement(S.Actions, null, 'invoices' in order.node && order.node.invoices && order.node.invoices.length ? React.createElement(DropdownMenu, {
        type: "clickable",
        header: React.createElement(IconButton, {
          testingContext: "expandButton",
          name: "expand",
          size: 28
        }),
        items: [{
          onClick: function onClick() {
            return downloadInvoice(order.node);
          },
          content: React.createElement("span", null, React.createElement(FormattedMessage, {
            id: "@next.components.molecules.OrderTabel.OrderTabel.2670827143",
            defaultMessage: "Download invoice",
            description: "action in popup menu in order view"
          }))
        }]
      }) : null));
    }));
  }));
};