import React from "react";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import stripeWebp from "../../../../images/Stripe_2x.webp";
import { ErrorMessage, Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import { BraintreePaymentGateway, DummyPaymentGateway, StripePaymentGateway, AdyenPaymentGateway, BankTransferPaymentGateway } from "..";
import * as S from "./styles";

/**
 * Payment Gateways list
 */
var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      _processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      errors = _ref.errors,
      onError = _ref.onError;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.BRAINTREE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBraintreeInput",
          name: "payment-method",
          value: "credit-card",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBraintreeName"
        }, name))), checked && React.createElement(BraintreePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.DUMMY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, name))), checked && React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      case PROVIDERS.STRIPE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayStripeInput",
          name: "payment-method",
          value: "stripe",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayStripeName"
        }, name), isWebpSupported() ? React.createElement("img", {
          src: stripeWebp,
          style: {
            width: '232px',
            marginLeft: '10px'
          }
        }) : null)), checked && React.createElement(StripePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.ADYEN.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayAdyenInput",
          name: "payment-method",
          value: "adyen",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayAdyenName"
        }, name))), checked && React.createElement(AdyenPaymentGateway, {
          config: config,
          formRef: formRef,
          scriptConfig: PROVIDERS.ADYEN.script,
          styleConfig: PROVIDERS.ADYEN.style,
          processPayment: function processPayment() {
            return _processPayment(id);
          },
          submitPayment: submitPayment,
          submitPaymentSuccess: submitPaymentSuccess,
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.BANK_TRANSFER.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBankTransferInput",
          name: "payment-method",
          value: "bank_transfer",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBankTransferName"
        }, name))), checked && React.createElement(BankTransferPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };