import _objectSpread from "@babel/runtime/helpers/objectSpread";
import * as React from "react";
import { Query } from "react-apollo";
import { Error } from "../components/Error";
import Loader from "../components/Loader";
import { maybe } from "./utils";
export function TypedQuery(query) {
  var currency = window.localStorage.getItem('currency');
  return function (props) {
    var _ref = props,
        children = _ref.children,
        _ref$displayError = _ref.displayError,
        displayError = _ref$displayError === void 0 ? true : _ref$displayError,
        _ref$displayLoader = _ref.displayLoader,
        displayLoader = _ref$displayLoader === void 0 ? true : _ref$displayLoader,
        _ref$renderOnError = _ref.renderOnError,
        renderOnError = _ref$renderOnError === void 0 ? false : _ref$renderOnError,
        _ref$alwaysRender = _ref.alwaysRender,
        alwaysRender = _ref$alwaysRender === void 0 ? false : _ref$alwaysRender,
        _ref$fetchPolicy = _ref.fetchPolicy,
        fetchPolicy = _ref$fetchPolicy === void 0 ? "cache-and-network" : _ref$fetchPolicy,
        errorPolicy = _ref.errorPolicy,
        loaderFull = _ref.loaderFull,
        skip = _ref.skip,
        variables = _ref.variables,
        onCompleted = _ref.onCompleted;
    return React.createElement(Query, {
      query: query,
      variables: variables,
      skip: skip,
      fetchPolicy: fetchPolicy,
      errorPolicy: errorPolicy,
      onCompleted: onCompleted,
      context: {
        headers: {
          Currency: currency ? JSON.parse(currency) : 'EUR'
        }
      }
    }, function (queryData) {
      var error = queryData.error,
          loading = queryData.loading,
          data = queryData.data,
          fetchMore = queryData.fetchMore;
      var hasData = maybe(function () {
        return !!Object.keys(data).length;
      }, false);

      var loadMore = function loadMore(mergeFunc, extraVariables) {
        return fetchMore({
          query: query,
          updateQuery: function updateQuery(previousResults, _ref2) {
            var fetchMoreResult = _ref2.fetchMoreResult;

            if (!fetchMoreResult) {
              return previousResults;
            }

            return mergeFunc(previousResults, fetchMoreResult);
          },
          variables: _objectSpread({}, variables, extraVariables)
        });
      };

      if (displayError && error && !hasData) {
        return React.createElement(Error, {
          error: error.message
        });
      }

      if (displayLoader && loading && !hasData) {
        return React.createElement(Loader, {
          full: loaderFull
        });
      }

      if (hasData || renderOnError && error || alwaysRender) {
        return children(_objectSpread({}, queryData, {
          loadMore: loadMore
        }));
      }

      return null;
    });
  };
}