import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: table-cell;\n  line-height: 2rem;\n  vertical-align: middle;\n  margin-bottom: 1rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  border: 3px solid ", ";\n  padding: 0.5rem 1rem;\n  padding-bottom: 0;\n  border-radius: 5px;\n\n  margin-top: 20px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.divider;
});
export var Line = styled.span(_templateObject2());