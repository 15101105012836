import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1.5;\n\n  h3 {\n    margin-bottom: 1rem;\n  }\n  ul {\n    list-style: disclosure-closed;\n    padding-left: 30px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  color: ", ";\n  vertical-align: middle;\n  display: table-cell;\n  line-height: 1.2;\n  padding-bottom: 10px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 120px;\n  float: left;\n  text-align: left;\n  > img {\n    width: 100px;\n    height: auto;\n  }  \n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    float: none;\n    width: 100%;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  float: left;\n  width: 50%;\n  padding: 0;\n  padding-right: 2rem;\n  margin-top: 2rem;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  font-size: 1.5rem;\n  color: ", ";\n  margin-bottom: 15px;\n  border-bottom: 1px solid ", ";\n  display: table;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.secondaryDark;
}, function (props) {
  return props.theme.colors.dividerDark;
});
export var Item = styled.div(_templateObject3(), media.smallScreen(_templateObject4()));
export var Image = styled.div(_templateObject5());
export var Title = styled.div(_templateObject6(), function (props) {
  return props.theme.colors.secondaryDark;
});
export var Description = styled.div(_templateObject7());