import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { AddNewTile, TileGrid } from "@components/atoms";
import { CreditCardTile } from "@components/molecules";
export var CreditCardGrid = function CreditCardGrid(_ref) {
  var creditCards = _ref.creditCards,
      onClick = _ref.onClick,
      markOnClick = _ref.markOnClick;
  var addNewTile = [React.createElement(AddNewTile, {
    type: "card"
  })];
  var ccTiles = creditCards.map(function (cc) {
    return React.createElement(CreditCardTile, _extends({}, cc, {
      onClick: onClick,
      markOnClick: markOnClick
    }));
  }); // return <TileGrid elements={addNewTile.concat(ccTiles)} />;

  return React.createElement(TileGrid, {
    elements: ccTiles
  });
};