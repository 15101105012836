import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  VAT: {
    "id": "intl.commonMessages.VAT",
    defaultMessage: "VAT"
  },
  footerUrlDemo: {
    "id": "intl.commonMessages.footerUrlDemo",
    defaultMessage: "https://dynfi.com/en/demo"
  },
  footerUrlShop: {
    "id": "intl.commonMessages.footerUrlShop",
    defaultMessage: "https://shop.dynfi.com/"
  },
  footerUrlDFM: {
    "id": "intl.commonMessages.footerUrlDFM",
    defaultMessage: "https://dynfi.com/en/dynfi-manager"
  },
  footerUrlDFF: {
    "id": "intl.commonMessages.footerUrlDFF",
    defaultMessage: "https://dynfi.com/en/dynfi-firewall"
  },
  footerUrlCompany: {
    "id": "intl.commonMessages.footerUrlCompany",
    defaultMessage: "https://dynfi.com/en/you-and-us/dynfi-company"
  },
  footerUrlPartner: {
    "id": "intl.commonMessages.footerUrlPartner",
    defaultMessage: "https://dynfi.com/en/you-and-us/dynfi-partnerships"
  },
  footerUrlPress: {
    "id": "intl.commonMessages.footerUrlPress",
    defaultMessage: "https://dynfi.com/en/you-and-us/dynfi-in-the-press"
  },
  footerUrlDoc: {
    "id": "intl.commonMessages.footerUrlDoc",
    defaultMessage: "https://dynfi.com/en/documentations/"
  },
  footerUrlDlDFM: {
    "id": "intl.commonMessages.footerUrlDlDFM",
    defaultMessage: "https://dynfi.com/en/download/dynfi-manager"
  },
  footerUrlDlDFF: {
    "id": "intl.commonMessages.footerUrlDlDFF",
    defaultMessage: "https://dynfi.com/en/download/dynfi-firewall"
  },
  footerUrlNewsletter: {
    "id": "intl.commonMessages.footerUrlNewsletter",
    defaultMessage: "https://dynfiwebdev.osnet.lan/subscribe-to-newsletter"
  },
  footerUrlContact: {
    "id": "intl.commonMessages.footerUrlContact",
    defaultMessage: "https://dynfi.com/en/you-and-us/contact-us"
  },
  storedCards: {
    "id": "intl.commonMessages.storedCards",
    defaultMessage: "Stored cards"
  },
  acceptedContracts: {
    "id": "intl.commonMessages.acceptedContracts",
    defaultMessage: "Contracts"
  },
  numberOfDevices: {
    "id": "intl.commonMessages.numberOfDevices",
    defaultMessage: "Number of devices"
  },
  numberOfDevicesShort: {
    "id": "intl.commonMessages.numberOfDevicesShort",
    defaultMessage: "# of devices"
  },
  euVatNumber: {
    "id": "intl.commonMessages.euVatNumber",
    defaultMessage: "EU VAT number"
  },
  preferredCurrency: {
    "id": "intl.commonMessages.preferredCurrency",
    defaultMessage: "Currency used for automatic license prolongation"
  },
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "search"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Out of stock"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Low stock"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "No items available"
  },
  availableSoon: {
    "id": "intl.commonMessages.availableSoon",
    defaultMessage: "Available soon"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Not available for purchase"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Will become available for purchase on {date} at {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "You might like"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Please choose payment method."
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "Please provide email address."
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Account"
  },
  cart: {
    "id": "intl.commonMessages.cart",
    defaultMessage: "cart"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "My Account"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Order history"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "Address book"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Log Out"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Last Name"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Password"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Quantity"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "SKU"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maximum quantity is {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Quantity"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "Subtotal"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "Shipping"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "Promo code"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "Total"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "Total Price"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Checkout"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "Email Address"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "Email"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Loading"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Products"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Price"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Variant"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Phone"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "Phone number: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Save"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Add"
  },
  apply: {
    "id": "intl.commonMessages.apply",
    defaultMessage: "Apply"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "Filters"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "CLEAR FILTERS"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Cancel"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Home"
  },
  byPurchasingYouAccept: {
    "id": "intl.commonMessages.byPurchasingYouAccept",
    defaultMessage: "By purchasing this product you accept the"
  },
  contract: {
    "id": "intl.commonMessages.contract",
    defaultMessage: "contract"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Address"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Shipping"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Payment"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Review"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Continue to Shipping"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Continue to Payment"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Continue to Review"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Place order"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Add new address"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "SHIPPING METHOD"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "BILLING ADDRESS"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "PAYMENT METHOD"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "REVIEW ORDER"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Shipping Address"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "CONTINUE SHOPPING"
  },
  vatSettings: {
    "id": "intl.checkoutMessages.vatSettings",
    defaultMessage: "EU VAT INFORMATION"
  },
  bankTransferThankYou: {
    "id": "intl.checkoutMessages.bankTransferThankYou",
    defaultMessage: "for your order on shop.dynfi.com with a payment by wire transfer."
  },
  bankTransferOrderInfo: {
    "id": "intl.checkoutMessages.bankTransferOrderInfo",
    defaultMessage: "Please specify the order reference ORDERID in the transfer information.\nIn order to simplify the processing of your order and to shorten the processing time, we invite you to send us by e-mail the proof of your wire transfer to info@dynfi.com.\nYour order will be delivered as soon as we receive this e-mail or, failing that, upon receipt of the transfer."
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Clear..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Price Low-High"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Price High-Low"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Name Increasing"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Name Decreasing"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Last updated Ascending"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Last updated Descending"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Not charged"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    defaultMessage: "Partially charged"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Fully charged"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    defaultMessage: "Partially refunded"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Fully refunded"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Draft"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "Unfulfilled"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: "Partially fulfilled"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "Fulfilled"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Canceled"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}
export var paymentMessages = defineMessages({
  bankTransferInfo: {
    "id": "intl.paymentMessages.bankTransferInfo",
    defaultMessage: "Bank transfer has to be made on our HSBC bank account (in Euro) Information provided shall include order number to ease the processing of your order.<br /><br />\nName of the company: DYNFI<br /><br />\nIBAN number: FR76 3005 6001 2301 2300 3923 622<br /><br />\nBank reference: CCFRFRPP<br /><br />\nBank location: HSBC FR BBC ILE DE FRANCE OUEST"
  }
});