import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { Formik } from "formik";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { TextField } from "@components/molecules";
import * as S from "./styles";
export var VatInformationForm = function VatInformationForm(_ref) {
  var initial = _ref.initial,
      handleSubmit = _ref.handleSubmit,
      hide = _ref.hide,
      error = _ref.error;
  var intl = useIntl();
  var fieldErrors = {};

  if (error) {
    error.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;

      if (field && message) {
        fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
          message: message
        }]) : [{
          message: message
        }];
      }
    });
  }

  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: {
      euVatNumber: initial
    },
    onSubmit: function onSubmit(values, _ref3) {
      var setSubmitting = _ref3.setSubmitting;
      handleSubmit(values.euVatNumber);
      setSubmitting(false);
    },
    validateOnChange: false
  }, function (_ref4) {
    var handleChange = _ref4.handleChange,
        handleSubmit = _ref4.handleSubmit,
        handleBlur = _ref4.handleBlur,
        values = _ref4.values,
        errors = _ref4.errors,
        touched = _ref4.touched,
        isSubmitting = _ref4.isSubmitting,
        isValid = _ref4.isValid;
    return React.createElement(S.Form, {
      onSubmit: handleSubmit
    }, React.createElement(TextField, {
      name: "euVatNumber",
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "EU VAT number",
        "id": "@next.components.organisms.CheckoutAddress.VatInformationForm.242609773"
      }, "id", "@next.components.organisms.CheckoutAddress.VatInformationForm.242609773")),
      type: "text",
      value: values.euVatNumber,
      onBlur: handleBlur,
      onChange: handleChange,
      errors: touched.euVatNumber && errors.euVatNumber ? [{
        message: errors.euVatNumber
      }] : undefined || fieldErrors.euVatNumber
    }), React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting,
      size: "sm",
      onClick: hide
    }, React.createElement(FormattedMessage, commonMessages.apply)));
  }));
};