import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProductsList {\n    shop {\n      description\n      name\n      headerText\n      mainTextJson\n      transFR: translation(languageCode: FR) {\n          description\n          headerText\n          mainTextJson\n      }\n      transDE: translation(languageCode: DE) {\n          description\n          headerText\n          mainTextJson\n      }\n      homepageCollection {\n        id\n        backgroundImage {\n          url\n        }\n        name\n      }\n    }\n    categories(level: 0, first: 12) {\n      edges {\n        node {\n          id\n          name\n          backgroundImage {\n            url\n          }\n          transFR: translation(languageCode: FR) {\n            name\n          }\n          transDE: translation(languageCode: DE) {\n            name\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var homePageQuery = gql(_templateObject());
export var TypedHomePageQuery = TypedQuery(homePageQuery);