import * as React from "react";
import { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CartPage, CheckoutPage, PasswordReset, ThankYouPage } from "@pages";
import { CheckoutLogin, NotFound } from "../../components";
import UserAccount, * as accountPaths from "../../userAccount/routes";
import { OrderDetails } from "../../userAccount/views";
import { LicenseDetails } from "../../licenseDetails/views";
import { ProxmoxDetails } from "../../proxmoxDetails/views";
import { Account, AccountConfirm } from "../../views/Account";
import { ArticlePage } from "../../views/Article";
import { CategoryPage } from "../../views/Category";
import { CollectionPage } from "../../views/Collection";
import { HomePage } from "../../views/Home";
import { ProductPage } from "../../views/Product";
import { SearchPage } from "../../views/Search";
import * as paths from "./paths";

function usePageViews() {
  var location = useLocation();

  var _useMatomo = useMatomo(),
      trackPageView = _useMatomo.trackPageView;

  React.useEffect(function () {
    trackPageView();
  }, [location]);

  if (process.env.TRACKING_ID !== undefined) {
    useEffect(function () {
      ReactGA.pageview(location.pathname);
    }, [location]);
  }
}

export var Routes = function Routes() {
  usePageViews();
  return React.createElement(Switch, null, React.createElement(Route, {
    exact: true,
    path: paths.baseUrl,
    component: HomePage
  }), React.createElement(Route, {
    path: paths.searchUrl,
    component: SearchPage
  }), React.createElement(Route, {
    path: paths.categoryUrl,
    component: CategoryPage
  }), React.createElement(Route, {
    path: paths.collectionUrl,
    component: CollectionPage
  }), React.createElement(Route, {
    path: paths.productUrl,
    component: ProductPage
  }), React.createElement(Route, {
    path: paths.cartUrl,
    component: CartPage
  }), React.createElement(Route, {
    path: paths.checkoutLoginUrl,
    component: CheckoutLogin
  }), React.createElement(Route, {
    path: paths.pageUrl,
    component: ArticlePage
  }), React.createElement(Route, {
    path: accountPaths.baseUrl,
    component: UserAccount
  }), React.createElement(Route, {
    path: accountPaths.userOrderDetailsUrl,
    component: OrderDetails
  }), React.createElement(Route, {
    path: paths.guestOrderDetailsUrl,
    component: OrderDetails
  }), React.createElement(Route, {
    path: paths.accountUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.accountConfirmUrl,
    component: AccountConfirm
  }), React.createElement(Route, {
    path: paths.orderHistoryUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.licensesUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.proxmoxUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.storedCardsUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.acceptedContractsUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.licenseDetailsUrl,
    component: LicenseDetails
  }), React.createElement(Route, {
    path: paths.proxmoxDetailsUrl,
    component: ProxmoxDetails
  }), React.createElement(Route, {
    path: paths.addressBookUrl,
    component: Account
  }), React.createElement(Route, {
    path: paths.passwordResetUrl,
    component: PasswordReset
  }), React.createElement(Route, {
    path: paths.checkoutUrl,
    component: CheckoutPage
  }), React.createElement(Route, {
    path: paths.orderFinalizedUrl,
    component: ThankYouPage
  }), React.createElement(Route, {
    component: NotFound
  }));
};
export default Routes;