import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n     width: 100%;\n     margin-left: 0;\n  "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  white-space: pre-wrap;\n  font-family: Monospace;\n  width: 60%;\n  margin-left: 20%;\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n  ", "\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  height: 5rem;\n  padding-top: 2rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  button {\n    margin-left: 2rem;\n  }\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  width: 60%;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  color: #ff0909;\n  padding: 0.5rem;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n     flex-direction: column;\n  "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 0.5rem;\n\n  > div {\n    width: 100%;\n  }\n\n  ", "\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n     width: 100%;\n     margin-left: 0;\n  "]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n  margin-left: 25%;\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n    flex-direction: column;\n    width: 100%;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 70%;\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  padding: 1.5rem 0;\n  width: 95%;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: none;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 95%;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 4rem;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  height: auto;\n  margin-bottom: 1.5rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 15%;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 15%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: #7d7d7d;\n  cursor: default;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  height: 5rem;\n  cursor: pointer;\n  border-bottom: 1px solid #EEE;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Row = styled.div(_templateObject2());
export var HeaderRow = styled(Row)(_templateObject3());
export var UuidField = styled.div(_templateObject4());
export var DateField = styled.div(_templateObject5());
export var Value = styled.div(_templateObject6());
export var TileWrapper = styled.div(_templateObject7());
export var Header = styled.div(_templateObject8(), function (props) {
  return props.theme.colors.dividerDark;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var HeaderSmall = styled(Header)(_templateObject9());
export var Content = styled.div(_templateObject10());
export var ContentOneLine = styled.div(_templateObject11(), media.smallScreen(_templateObject12()));
export var ContentEdit = styled.div(_templateObject13(), media.smallScreen(_templateObject14()));
export var ContentEditOneLine = styled.div(_templateObject15(), media.smallScreen(_templateObject16()));
export var Error = styled.div(_templateObject17());
export var ContentExtendInput = styled.div(_templateObject18());
export var Form = styled.form(_templateObject19(), function (props) {
  return props.theme.tile.backgroundColor;
});
export var FormButtons = styled.div(_templateObject20());
export var Code = styled.div(_templateObject21(), media.smallScreen(_templateObject22()));