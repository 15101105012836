import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { Formik } from "formik";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, ButtonLink, Radio } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var CurrencyChangeForm = function CurrencyChangeForm(_ref) {
  var initial = _ref.initial,
      handleSubmit = _ref.handleSubmit,
      hide = _ref.hide,
      error = _ref.error;
  var intl = useIntl();
  var fieldErrors = {};

  if (error) {
    error.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;

      if (field && message) {
        fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
          message: message
        }]) : [{
          message: message
        }];
      }
    });
  }

  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: {
      currency: initial
    },
    onSubmit: function onSubmit(values, _ref3) {
      var setSubmitting = _ref3.setSubmitting;
      handleSubmit({
        currency: values.currency
      });
      setSubmitting(false);
    },
    validateOnChange: false
  }, function (_ref4) {
    var handleChange = _ref4.handleChange,
        handleSubmit = _ref4.handleSubmit,
        handleBlur = _ref4.handleBlur,
        values = _ref4.values,
        setFieldValue = _ref4.setFieldValue,
        setFieldTouched = _ref4.setFieldTouched,
        isSubmitting = _ref4.isSubmitting;
    var isUsd = values.currency === 'USD';
    return React.createElement(S.Form, {
      onSubmit: handleSubmit
    }, React.createElement(Radio, {
      name: "currency",
      value: "EUR",
      checked: !isUsd,
      onChange: function onChange() {
        return setFieldValue("currency", 'EUR');
      }
    }, "EUR"), React.createElement(Radio, {
      name: "currency",
      value: "USD",
      checked: isUsd,
      onChange: function onChange() {
        return setFieldValue("currency", 'USD');
      }
    }, "USD"), React.createElement(S.FormButtons, null, React.createElement(ButtonLink, {
      testingContext: "cancelButton",
      type: "button",
      color: "secondary",
      onClick: hide
    }, React.createElement(FormattedMessage, commonMessages.cancel)), React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting,
      size: "sm"
    }, React.createElement(FormattedMessage, commonMessages.save))));
  }));
};