import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      mutation ProxmoxLicenseProlong($id: ID!) {\n        proxmoxLicenseProlong(id: $id) {\n          licenseErrors {\n            field\n            message\n            code\n          }\n        }\n      }\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProxmoxLicensesByUser {\n    me {\n      proxmoxLicenses(first: 100) {\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        edges {\n          node {\n            id\n            uuid\n            created\n            subscriptionType\n            numberOfCpus\n            subscriptionNumber\n            isPbs\n            expires\n            hasExpired\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React, { useState } from "react";
import { getAuthToken } from "@saleor/sdk";
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost';
import { Loader } from "@components/atoms";
import { ProxmoxTabel } from "@components/molecules";
var proxmoxLicensesByUser = gql(_templateObject());
export var Proxmox = function Proxmox(_ref) {
  var _data$me;

  var history = _ref.history;
  var authToken = getAuthToken();
  var client = new ApolloClient({
    uri: process.env.API_URI,
    headers: {
      Authorization: authToken ? "JWT ".concat(authToken) : null
    }
  });

  var handleProlong = function handleProlong(id) {
    var PROLONG = gql(_templateObject2());
    client.mutate({
      mutation: PROLONG,
      variables: {
        id: id
      }
    }).then(function (resp) {
      location.pathname = "/checkout/address";
    });
  };

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      data = _useState2[0],
      setData = _useState2[1];

  if (!data) {
    client.query({
      query: proxmoxLicensesByUser
    }).then(function (resp) {
      setData(resp.data);
    });
  }

  return !data ? React.createElement(Loader, null) : React.createElement(React.Fragment, null, React.createElement(ProxmoxTabel, {
    licenses: data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.proxmoxLicenses.edges,
    history: history,
    onProlong: handleProlong
  }));
};