import { Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { paymentMessages } from "@temp/intl";
import * as S from "./styles";

var BankTransferPaymentGateway = function BankTransferPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId;
  var intl = useIntl();
  var translated = intl.formatMessage(paymentMessages.bankTransferInfo);
  return React.createElement(Formik, {
    initialValues: {},
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment('bank-transfer');
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement("form", {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "dummyPaymentGatewayForm"
    }, React.createElement(S.Wrapper, null, React.createElement("div", {
      dangerouslySetInnerHTML: {
        __html: translated
      }
    })));
  });
};

export { BankTransferPaymentGateway };