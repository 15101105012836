import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import { VatInformationForm } from "./VatInformationForm";
import * as S from "./styles";
var TAXED_COUNTRIES = ['AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FO', 'FI', 'DE', 'GI', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'MD', 'MC', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'UA', 'VA', 'RS', 'IM', 'RS', 'GP', 'MQ', 'RE'];
/**
 * Address form used in checkout.
 */

var CheckoutAddress = function CheckoutAddress(_ref) {
  var checkoutShippingAddress = _ref.checkoutShippingAddress,
      checkoutBillingAddress = _ref.checkoutBillingAddress,
      _ref$billingAsShippin = _ref.billingAsShippingAddress,
      billingAsShippingAddress = _ref$billingAsShippin === void 0 ? false : _ref$billingAsShippin,
      email = _ref.email,
      selectedUserShippingAddressId = _ref.selectedUserShippingAddressId,
      selectedUserBillingAddressId = _ref.selectedUserBillingAddressId,
      userAddresses = _ref.userAddresses,
      countries = _ref.countries,
      userId = _ref.userId,
      shippingFormId = _ref.shippingFormId,
      shippingFormRef = _ref.shippingFormRef,
      billingFormId = _ref.billingFormId,
      billingFormRef = _ref.billingFormRef,
      shippingAddressRequired = _ref.shippingAddressRequired,
      setShippingAddress = _ref.setShippingAddress,
      setBillingAddress = _ref.setBillingAddress,
      setBillingAsShippingAddress = _ref.setBillingAsShippingAddress,
      shippingErrors = _ref.shippingErrors,
      billingErrors = _ref.billingErrors,
      newAddressFormId = _ref.newAddressFormId,
      checkoutEuVatNumber = _ref.checkoutEuVatNumber,
      setEuVatNumber = _ref.setEuVatNumber,
      euVatErrors = _ref.euVatErrors;

  var _useState = useState(checkoutBillingAddress),
      _useState2 = _slicedToArray(_useState, 2),
      localBillingAddress = _useState2[0],
      setLocalBillingAddress = _useState2[1];

  var _useState3 = useState(checkoutShippingAddress),
      _useState4 = _slicedToArray(_useState3, 2),
      localShippingAddress = _useState4[0],
      setLocalShippingAddress = _useState4[1];

  var showEuVat = false;

  if (shippingAddressRequired) {
    if (localShippingAddress || localBillingAddress) {
      var country = localShippingAddress ? localShippingAddress.country.code : localBillingAddress.country.code;
      if (country == 'FR' || TAXED_COUNTRIES.includes(country)) showEuVat = true;
    }
  } else if (localBillingAddress) {
    var _country = localBillingAddress.country.code;
    if (_country == 'FR' || TAXED_COUNTRIES.includes(_country)) showEuVat = true;
  }

  return React.createElement(S.Wrapper, null, shippingAddressRequired && React.createElement(React.Fragment, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), userAddresses ? React.createElement(AddressGridSelector, {
    testingContext: "shipping",
    formId: shippingFormId,
    formRef: shippingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserShippingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: shippingErrors,
    onChange: function onChange(address) {
      return setLocalShippingAddress(address);
    },
    onSelect: function onSelect(address, id) {
      return setShippingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "shippingAddressForm",
    formId: shippingFormId,
    formRef: shippingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: _objectSpread({}, checkoutShippingAddress, {
      email: email
    }),
    handleSubmit: function handleSubmit(address) {
      setShippingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: true,
    errors: shippingErrors
  })), React.createElement(S.Divider, null)), React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.billingAddress)), shippingAddressRequired && React.createElement(Checkbox, {
    "data-test": "checkoutAddressBillingAsShippingCheckbox",
    name: "billing-same-as-shipping",
    checked: billingAsShippingAddress,
    onChange: function onChange() {
      return setBillingAsShippingAddress(!billingAsShippingAddress);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutAddress.CheckoutAddress.3912924864",
    defaultMessage: "Same as shipping address"
  })), !billingAsShippingAddress && React.createElement(React.Fragment, null, shippingAddressRequired && React.createElement(S.Divider, null), userAddresses ? React.createElement(AddressGridSelector, {
    testingContext: "billing",
    formId: billingFormId,
    formRef: billingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserBillingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: billingErrors,
    onChange: function onChange(address) {
      return setLocalBillingAddress(address);
    },
    onSelect: function onSelect(address, id) {
      return setBillingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "billingAddressForm",
    formId: billingFormId,
    formRef: billingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: checkoutBillingAddress || undefined,
    handleSubmit: function handleSubmit(address) {
      return setBillingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: !shippingAddressRequired,
    errors: billingErrors
  }))), React.createElement(S.Divider, null), showEuVat ? React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.vatSettings)), React.createElement(VatInformationForm, {
    initial: checkoutEuVatNumber,
    handleSubmit: function handleSubmit(euVatNumber) {
      setEuVatNumber(euVatNumber);
    },
    error: euVatErrors
  })) : null);
};

export { CheckoutAddress };