import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, OverlayTheme, OverlayType } from "..";

var CheckoutAsGuest = function CheckoutAsGuest(_ref) {
  var overlay = _ref.overlay,
      checkoutUrl = _ref.checkoutUrl;
  return React.createElement("div", {
    className: "checkout-login__guest"
  }, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.722948239",
    defaultMessage: "No account ?"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.1545605667",
    defaultMessage: "If you are not register yet, don\u2019t worry. You can do it right now."
  })), React.createElement(Button, {
    className: "button secondary",
    testingContext: "continueAsGuestButton",
    onClick: function onClick() {
      return overlay.show(OverlayType.register, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.2556234295",
    defaultMessage: "Register"
  }), " "));
};

export default CheckoutAsGuest;