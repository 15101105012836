import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
export var QuantityInput = function QuantityInput(_ref) {
  var disabled = _ref.disabled,
      quantity = _ref.quantity,
      minQuantity = _ref.minQuantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      hideErrors = _ref.hideErrors,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId,
      label = _ref.label;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTooMuch = _useState2[0],
      setIsTooMuch = _useState2[1];

  var _useState3 = useState(minQuantity),
      _useState4 = _slicedToArray(_useState3, 2),
      value = _useState4[0],
      setValue = _useState4[1];

  var intl = useIntl();
  useEffect(function () {
    setIsTooMuch(!isNaN(quantity) && quantity > maxQuantity);
  }, [quantity, maxQuantity]);
  var tt = null;
  useEffect(function () {
    onQuantityChange(value);
  }, [value]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    setValue(evt.target.value);
  };

  var fixQuantity = function fixQuantity() {
    if (value < minQuantity) onQuantityChange(minQuantity);
  };

  var quantityErrors = !hideErrors && isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  var mlabel = label != null ? label : intl.formatMessage(commonMessages.quantity);
  return React.createElement(TextField, {
    name: "quantity",
    type: "number",
    label: mlabel,
    min: minQuantity,
    value: quantity.toString(),
    disabled: disabled,
    onChange: handleQuantityChange,
    onBlur: fixQuantity,
    errors: quantityErrors,
    "data-test": testingContext,
    "data-testId": testingContextId
  });
};
QuantityInput.displayName = "QuantityInput";
export default QuantityInput;