import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RichTextContent } from "@components/atoms";
import { ProductComponents } from "@components/organisms";
import { ProductPdf } from "@components/molecules";
import * as S from "./styles";
var TABS;

(function (TABS) {
  TABS[TABS["INTHEBOX"] = 0] = "INTHEBOX";
  TABS[TABS["DESCRIPTION"] = 1] = "DESCRIPTION";
  TABS[TABS["ATTRIBUTES"] = 2] = "ATTRIBUTES";
  TABS[TABS["COMPONENTS"] = 3] = "COMPONENTS";
})(TABS || (TABS = {}));

export var ProductDescription = function ProductDescription(_ref) {
  var _ref$summaryJson = _ref.summaryJson,
      summaryJson = _ref$summaryJson === void 0 ? "" : _ref$summaryJson,
      _ref$descriptionJson = _ref.descriptionJson,
      descriptionJson = _ref$descriptionJson === void 0 ? "" : _ref$descriptionJson,
      attributes = _ref.attributes,
      components = _ref.components,
      _ref$pdfUrl = _ref.pdfUrl,
      pdfUrl = _ref$pdfUrl === void 0 ? "" : _ref$pdfUrl;
  var hasSummary = summaryJson && summaryJson.length > 2;

  var _React$useState = React.useState(hasSummary ? TABS.INTHEBOX : TABS.DESCRIPTION),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeTab = _React$useState2[0],
      setActiveTab = _React$useState2[1];

  return React.createElement(S.Wrapper, null, React.createElement(S.Tabs, null, hasSummary ? React.createElement(S.TabTitle, {
    active: activeTab === TABS.INTHEBOX,
    onMouseEnter: function onMouseEnter(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.INTHEBOX);
    },
    onClick: function onClick(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.INTHEBOX);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.1596013087",
    defaultMessage: "In the box"
  })) : null, React.createElement(S.TabTitle, {
    active: activeTab === TABS.DESCRIPTION,
    onMouseEnter: function onMouseEnter(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.DESCRIPTION);
    },
    onClick: function onClick(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.DESCRIPTION);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.3374163063",
    defaultMessage: "Description"
  })), attributes && attributes.length ? React.createElement(S.TabTitle, {
    active: activeTab === TABS.ATTRIBUTES,
    onMouseEnter: function onMouseEnter(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.ATTRIBUTES);
    },
    onClick: function onClick(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.ATTRIBUTES);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.4153345096",
    defaultMessage: "Attributes"
  })) : null, attributes && components.length ? React.createElement(S.TabTitle, {
    active: activeTab === TABS.COMPONENTS,
    onMouseEnter: function onMouseEnter(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.COMPONENTS);
    },
    onClick: function onClick(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.COMPONENTS);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.244297040",
    defaultMessage: "Optional components"
  })) : null), React.createElement(S.DescriptionList, null, activeTab === TABS.INTHEBOX && (summaryJson ? React.createElement(RichTextContent, {
    descriptionJson: summaryJson
  }) : null), activeTab === TABS.DESCRIPTION && (descriptionJson ? React.createElement(RichTextContent, {
    descriptionJson: descriptionJson
  }) : null), activeTab === TABS.DESCRIPTION && React.createElement(ProductPdf, {
    pdfUrl: pdfUrl
  })), activeTab === TABS.ATTRIBUTES && React.createElement(S.AttributeList, null, attributes && attributes.map(function (attribute, index) {
    return React.createElement("li", {
      key: index
    }, React.createElement(S.AttributeName, null, attribute.attribute.name, " "), " ", React.createElement(S.AttributeValue, null, attribute.values.map(function (value) {
      return value.name;
    }).join(", ")));
  })), activeTab === TABS.COMPONENTS && React.createElement(S.Components, null, React.createElement(ProductComponents, {
    components: components
  })));
};