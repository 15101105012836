import React from "react";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import * as S from "./styles";
export var ProductTile = function ProductTile(_ref) {
  var product = _ref.product;
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;
  var attributesMap = {};

  for (var i in product === null || product === void 0 ? void 0 : product.attributes) {
    var a = product === null || product === void 0 ? void 0 : product.attributes[i];
    if (!('attribute' in a)) continue;

    if (a.values && a.values.length) {
      attributesMap[a.attribute.slug] = {
        name: a.attribute.name,
        value: a.values[0].name
      };
    }
  }

  var displayedAttrs = ['ethernet-ports', 'memory', 'processor', 'processor-cores', 'max-throughput'];
  var attributes = [];

  for (var i in displayedAttrs) {
    if (displayedAttrs[i] in attributesMap) attributes.push(attributesMap[displayedAttrs[i]]);
  }

  return React.createElement(S.Wrapper, null, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement(S.Title, {
    "data-test": "productTile"
  }, product.name), React.createElement(S.Price, {
    "data-test": "productPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price,
    showBothPrices: true,
    grossFirst: true
  })), attributes.map(function (attr) {
    return React.createElement(S.Detail, {
      key: attr.name
    }, attr.name, ": ", React.createElement("span", null, attr.value));
  }));
};