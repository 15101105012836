/* eslint-disable react/no-danger */
import { sanitize } from "dompurify";
import draftToHtml from "draftjs-to-html";
import React from "react";
export var RichTextContent = function RichTextContent(_ref) {
  var descriptionJson = _ref.descriptionJson;
  return React.createElement(React.Fragment, null, descriptionJson && React.createElement("div", {
    class: "richtextcontent",
    dangerouslySetInnerHTML: {
      __html: sanitize(draftToHtml(JSON.parse(descriptionJson)))
    }
  }));
};