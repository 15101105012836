import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-gap: 1rem;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 1.5rem;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  grid-gap: 2rem;\n\n  a {\n    margin-top: 50px;\n    \n    div > div {\n      position: relative;\n      top: -50px;\n      height: 75px;\n    }\n  }\n\n\n  ", "\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  color: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 120px;\n  float: left;\n  text-align: left;\n  > img {\n    width: 100px;\n    height: auto;\n  }  \n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  float: left;\n  width: 50%;\n  padding: 0;\n  padding-right: 2rem;\n  margin-top: 2rem;\n\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  font-size: 1.5rem;\n  color: ", ";\n  padding-bottom: 10px;\n  border-bottom: 1px solid ", ";\n  margin-bottom: 20px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.secondaryDark;
}, function (props) {
  return props.theme.colors.dividerDark;
});
export var Item = styled.div(_templateObject3());
export var Image = styled.div(_templateObject4());
export var Title = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.secondaryDark;
});
export var Description = styled.div(_templateObject6());
export var List = styled.div(_templateObject7(), media.largeScreen(_templateObject8()), media.smallScreen(_templateObject9()));