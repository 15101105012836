import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n    text-align: right;\n  "]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  font-weight: bold;\n  font-size: 1.3rem;\n  color: ", ";\n\n  ", "\n\n  .price_gross {\n    color: ", ";\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalText;\n  font-weight: bold;\n  text-transform: uppercase;\n  font-size: 1.3rem;\n  color: ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    text-align: right;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  grid-area: discountPrice;\n  font-size: 1.25rem;\n  color: ", ";\n  \n  ", "\n  \n  .price_gross {\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: discountText;\n  text-transform: uppercase;\n  font-size: 1.25rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    text-align: right;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  grid-area: shippingPrice;\n  font-size: 1.25rem;\n  \n  ", "\n  \n  .color: ", ";\n\n  .price_gross {\n    color: ", ";\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: shippingText;\n  text-transform: uppercase;\n  font-size: 1.25rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    text-align: right;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: subtotalPrice;\n  font-size: 1.25rem;\n  color: ", ";\n  ", "\n\n  .price_gross {\n    display: block;\n    white-space: nowrap;\n    color: $base-font-color-semitransparent;\n    font-weight: normal;\n    font-size: 75%;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: subtotalText;\n  text-transform: uppercase;\n  font-size: 1.25rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-areas:\n      \". subtotalText subtotalPrice\"\n      ", "\n      ", "\n      \". totalText totalPrice\";\n    grid-template-columns: 0.5fr 3.5fr 2fr;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  font-size: ", ";\n  grid-template-areas:\n    \". subtotalText subtotalPrice .\"\n    ", "\n    ", "\n    \". totalText totalPrice .\";\n  grid-template-columns: 4fr 1.1fr 1.55fr 0.1fr;\n  grid-gap: 2rem;\n  padding: 2rem 0;\n  ", "\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.showShipping && "\". shippingText shippingPrice .\"";
}, function (props) {
  return props.showDiscount && "\". discountText discountPrice .\"";
}, function (props) {
  return media.mediumScreen(_templateObject2(), props.showShipping && "\". shippingText shippingPrice\"", props.showDiscount && "\". discountText discountPrice\"");
});
export var SubtotalText = styled.div(_templateObject3());
export var SubtotalPrice = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, media.mediumScreen(_templateObject5()));
export var ShippingText = styled.div(_templateObject6());
export var ShippingPrice = styled.div(_templateObject7(), media.mediumScreen(_templateObject8()), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var DiscountText = styled.div(_templateObject9());
export var DiscountPrice = styled.div(_templateObject10(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, media.mediumScreen(_templateObject11()));
export var TotalText = styled.div(_templateObject12(), function (props) {
  return props.theme.colors.secondary;
});
export var TotalPrice = styled.div(_templateObject13(), function (props) {
  return props.theme.colors.secondary;
}, media.mediumScreen(_templateObject14()), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});